.upload__image-wrapper {
}

.btn-upload-area {
  width: 100%;
  border: 2px dashed $primary;
  min-height: 250px;
  background: linear-gradient(
    217.94deg,
    rgba(#f76552, 0.06) 1.68%,
    rgba(#dd2476, 0.08) 237.76%
  );

  &:hover {
    border: 2px dashed $secondary;
  }
}

.upload-icon {
  border: 0 !important;
  width: 100px;
  height: 100px;
  //background: linear-gradient(
  //  217.94deg,
  //  rgba(#f76552, 0.1) 1.68%,
  //  rgba(#dd2476, 0.1) 237.76%
  //);
  background-color: transparent;
  transform: scale(1);
  transition: all 0.3s ease-in-out;

  &:hover {
    //background: rgba($secondary, 0.1);
    background: transparent !important;
  }

  svg {
    height: 45px;
    width: 45px;
    path {
      fill: #f76552 !important;
    }
  }
}

.dragging {
  .upload-icon {
    transform: scale(1.1);
  }
  .small {
    color: $primary !important;
  }
}

.image-item {
  margin-top: 20px;
  text-align: center;

  .btn {
    font-size: 14px;
    padding: 2px 15px;
  }

  img {
    width: 80px;
    margin-bottom: 20px;
  }
}
