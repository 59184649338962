/* ------ START icon styles ------ */
.icon {
  background: #ffffff;
  border: 1px solid #e0eceb !important;
  border-radius: 10px;
  //box-shadow: 0px 6px 20px rgba(43, 154, 156, 0);

  width: 40px;
  height: 40px;
  position: relative;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #2d2d2d;

  cursor: pointer;
  //overflow: hidden;
  //transition: all .3s ease-in-out;

  //&:after {
  //  content: "";
  //  height: 100%;
  //  width: 100%;
  //  position: absolute;
  //  top: 0;
  //  background-color: #fff;
  //  visibility: visible;
  //  z-index: 1;
  //  transition: all .3s ease-out;
  //}

  // svgs inside icon class
  svg {
    width: 16px;
    height: 16px;
    z-index: 2;

    .hover-white {
      fill: #999fbf;
    }
  }

  .icon--active {
    color: var(--primary-n);
  }

  &:hover:not(&--logo),
  &--active:not(&--logo) {
    background: linear-gradient(0deg, #fff6f5, #fff6f5), linear-gradient(217.94deg, #f76552 1.68%, #dd2476 237.76%);

    //&:after {
    //  top: -80px;
    //  visibility: hidden;
    //  z-index: -1;
    //  transition: all .3s ease-out;
    //}

    svg path {
      // fill: var(--primary-n);
    }
    svg rect {
      stroke: var(--primary-n);
    }

    svg .hover-white {
      fill: var(--primary-n);
    }
    .hover-text {
      color: var(--primary-n);
    }
  }

  // hover text styles
  &--has-hover-text {
    // transition: all 0.3s ease-in-out;

    .hover-text {
      // z-index: -1;
      // // visibility: hidden;
      // position: absolute;
      // bottom: -30px;
      // width: 100px;
      // text-align: center;

      // font-size: 14px;
      // color: #0d1333;
      // transition: all 0.3s ease-in-out;
      opacity: 0;
    }

    &:hover {
      // margin-bottom: 30px !important;

      .hover-text {
        z-index: 1;
        // visibility: visible;
      }
    }
  }

  // sidebar logo
  &--logo {
    height: 34px;
    width: 34px;
    border: none !important;

    svg {
      width: 34px;
      height: 34px;
    }

    &:after {
      content: none;
    }

    &:hover {
      background: #fff;
      box-shadow: none;
    }
  }

  // footer logout
  &--logout {
    background: rgba(247, 101, 82, 0.1);
    border: 1px solid transparent !important;

    &:after {
      content: none;
    }

    svg .hover-white {
      fill: none;
      stroke: var(--primary);
    }

    &:hover {
      svg .hover-white {
        stroke: #fff;
        fill: transparent !important;
      }
    }
  }

  // end logout

  &--delete {
    height: 35px;
    width: 35px;

    &:after {
      content: none;
    }

    svg .hover-white {
      fill: transparent;
    }

    &:hover {
      svg .hover-white {
        stroke: #fff;
        fill: none !important;
      }
    }
  }
}

.icon {
  &--image {
    padding: 8px;
  }

  img {
    max-width: 100%;
  }
}

/* ------ END icon styles ------ */
