.triple-wrap {
  display: flex;
  width: 100%;
  height: calc(100vh - 180px);
  justify-content: center;
  align-items: center;

  .triple-login {
    width: 710px;
    background: #ffffff;
    border: 1px solid rgba(114, 114, 114, 0.14);
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 50px;

    .title {
      font-weight: 800;
      font-size: 22px;
      line-height: 28px;
      padding-bottom: 12px;
    }

    .desc {
      max-width: 480px;
      text-align: center;
      line-height: 28px;
    }

    .triple-login-content {
      width: 450px;
      .sign-up-title {
        padding-top: 24px;
        font-weight: 800;
        font-size: 18px;
        line-height: 18px;
      }

      .show-password .anticon {
        top: 15px;
      }

      .btn.btn-lg {
        padding: 8px;
      }
    }
  }
}

.logo-wrap {
  width: 100%;
  padding: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
