.search-groupAccount {
  .ant-select {
    .ant-select-selector {
      border: none !important;
      height: 50px;
      border-radius: 6px !important;
      overflow-y: auto;
      box-shadow: none !important;
      padding-left: 0px;
    }
    .ant-select-selection-item {
      height: 42px;
      display: flex;
      align-items: center;
      padding: 5px;
      background-color: var(--primary-n);
      color: white;
      border-radius: 6px;
    }
    .ant-select-selection-item-remove {
      margin-top: -7px;
      color: white;
      font-size: 14px;
      font-weight: 900;
    }
  }
  .ant-select-item-option-selected {
    background-color: blue !important;
  }
}

.ant-select-selection-placeholder {
  color: var(--light) !important;
}

.ant-select-item-option-selected {
  background-color: var(--light-bg) !important;
  //   color: blue !important;
}

.ant-select-item-option-state {
  color: var(--primary-n) !important;
}
.select-role {
  .ant-select-selector {
    border: none !important;
    height: 42px !important;
    border-radius: 6px !important;
    box-shadow: none !important;
    padding-left: 0 !important;

    [title='Select User Role'],
    [title='Velg bruker Rolle'] {
      color: var(--light);
    }
  }

  .ant-select-selection-item {
    height: auto;
    margin: 3px 10px;
    display: flex;
    align-items: center;
    color: #000;
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
}

.user-invitations {
  .user-invitation {
    border: 1px solid #e0eceb !important;
    border-radius: 6px !important;
    box-shadow: none !important;
    padding-left: 10px !important;
  }

  .ant-select-selector {
    border: 1px solid #e0eceb !important;
    border-radius: 6px !important;
    box-shadow: none !important;
    padding-left: 10px !important;
    height: 40px !important;

    .ant-select-selection-item {
      line-height: 38px !important;
    }
  }
}
