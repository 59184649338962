/* ===============================
    START settings page
================================*/
@import '~react-taggables-input/dist/tags.css';

.settings-page {
  .card {
    padding: 35px 30px;
  }

  &__header {
    flex-wrap: wrap;

    .card-title {
      width: 100%;
    }

    .divider {
      height: 30px;
      opacity: 0;
    }

    @include md {
      .card-title {
        width: auto;
        text-align: left;
      }
      .divider {
        opacity: 1;
      }
    }
  }

  #settingsTab {
    border: 0;
    justify-content: center;

    @include md {
      justify-content: flex-start;
    }

    .nav-item {
      overflow: hidden;
      border: 0;
      width: 100%;
      margin: 0 0 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .nav-link {
      border: 1px solid #e0eceb;
      border-radius: 10px;
      padding: 10px 15px;

      display: flex;
      align-items: center;
      white-space: nowrap;

      &.active {
        border: none;
      }

      &:hover {
        background-color: #fff;
        border-color: transparent;

        .settings-nav-svg {
          rect {
            opacity: 1;
          }

          path {
            stroke: #fff;
          }
        }
      }
    }

    .nav-icon {
      margin-right: 10px;
    }

    .nav-text {
      color: var(--text);
    }
  }

  #settingsTabContent {
    margin-top: 20px;

    label {
      color: #999fbf;
    }

    .form-control:focus,
    .form-control:active {
      border-color: transparent;
      box-shadow: none;
    }

    input:-internal-autofill-selected {
      background-color: #fff !important;
    }
  }

  .settings-nav-svg {
    rect {
      opacity: 0.1;
      transition: all 0.3s ease-in-out;
    }

    path {
      stroke: $primary;
      transition: all 0.3s ease-in-out;
    }
  }

  .active {
    .settings-nav-svg {
      rect {
        opacity: 1;
      }

      path {
        stroke: #fff;
      }
    }
  }
}

/* ======= settings page form fields ====== */
.settings-page {
  label {
    color: var(--light);
    font-size: 14px;
    margin: 0;
    text-transform: capitalize;
  }

  input {
    border: 0;
    padding: 0;
    font-size: 16px;
  }

  input[type='password'] {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  }

  .form-group {
    background: #ffffff;
    border: 1px solid #e0eceb;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px 20px;
  }

  .form-row {
    padding: 0 5px;
  }

  .form-row .col {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  .form-cta {
    .btn {
      padding: 10px 20px;
    }
  }

  .react-datepicker-wrapper {
    margin-top: 5px;
    display: block;
  }

  .settings-menu {
    .dropdown-toggle {
      padding: 0 !important;

      &:after {
        display: none;
      }
    }

    .dropdown-menu {
      border: 1px solid #e0eceb;
    }

    .settings-menu-icon {
      &.icon {
        width: 40px;
        height: 40px;
      }

      svg {
        path:last-child {
          fill: var(--primary-n);
        }
      }

      &:hover {
        svg {
          path:last-child {
            fill: white;
          }
        }
      }
    }
  }
}

/* ======= settings page profile ====== */
.card--profile {
  margin-bottom: 30px;
  background: #ffffff;
  border-radius: 10px;
  border: none;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  @include rwd(991) {
    margin-bottom: 0;
  }

  &__img {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    background-color: rgba($primary, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;

    .initials {
      padding: 10px;
      font-size: 20px;
    }

    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .edit-profile-image {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 4px;
    background: var(--primary-n);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    right: 0;

    svg {
      margin-left: 1px;
      margin-bottom: 1px;
    }
  }
}

.card--password {
  background: #ffffff;
  border-radius: 10px;
  border: none;
}
/* ======= settings page API ====== */
.card--add-api {
  border: 0;
  background: #ffffff;
  border-radius: 10px;

  input[type='text'],
  input[type='email'],
  input[type='number'],
  input[type='password'] {
    min-height: 30px;
  }

  .widget-info-icon {
    svg {
      width: 13px;
      margin-left: 6px;
      margin-top: -13px;
    }

    cursor: pointer;
  }
}

.card--all-accounts {
  padding-bottom: 45px;
  margin-top: 30px;
  border: 0;
  background: #ffffff;
  border-radius: 10px;

  .card--all-acount-container {
    overflow-y: auto;
    height: 493px;
  }

  @include rwd(991) {
    margin-top: 0;
  }
}

.card--account {
  padding: 6px 20px !important;
  background: #ffffff;
  border: 1px solid $light-border !important;
  box-sizing: border-box;
  border-radius: 20px;
  margin-bottom: 15px;
  align-items: center;
  height: 60px;

  &:last-child {
    margin-bottom: 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  .label {
    color: #999fbf;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .text,
  .date {
    font-size: 16px;
  }

  &__cta {
    width: 100px;
    border-radius: 10px;
    // background: #ffffff;
    border: 1px solid #c3dad8;
    // box-sizing: border-box;
    padding: 5px;
    height: 40px;
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    align-items: center;
    // flex-wrap: wrap;
  }

  .divider {
    width: 3px;
    height: 20px;
    opacity: 0.5;
    border-radius: 3px;
    margin-top: 3px;
    // transform: rotate(90deg);
  }

  &__edit,
  &__delete {
    cursor: pointer;

    svg path {
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      svg path {
        stroke: var(--primary) !important;
      }
    }
  }

  &__edit {
    &:hover {
      svg path {
        stroke: #28a745 !important;
      }
    }
  }
  // edit, delete
  &:hover {
    border: 1px solid var(--primary-n) !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
  }
}

.selected-card-api {
  border: 1px solid var(--primary-n) !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
}

// card account

/* ======= settings page billing ====== */
.card--membership {
  .card-plan-name {
    background: #2b9a9c;
    border-radius: 10px;
    color: #fff;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    .name {
      background: #158385;
      border-radius: 25px;
      padding: 10px 30px;
    }
  }
}

.card--payment-info {
  background: #ffffff;
  border: 1px solid #e0eceb;
  box-sizing: border-box;
  border-radius: 20px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.btn--add-pay-met {
  svg rect,
  svg path {
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    color: var(--primary-n);

    svg rect {
      fill-opacity: 1 !important;
    }

    svg path {
      stroke: #fff !important;
    }
  }
}

#search-order-history {
  background: #ffffff;
  border: 1px solid #e0eceb;
  border-bottom: 0;
  box-sizing: border-box;
  border-radius: 20px 20px 0 0;
  padding: 10px 25px;
}

.card--order-history {
  margin-top: 20px;

  @include rwd(991) {
    margin-top: 0;
  }

  .table {
    border: 1px solid #e0eceb;
  }

  &__data {
    thead th {
      border-bottom: 0;
    }

    th,
    td {
      padding: 20px;
    }
  }

  &__pagination {
    color: var(--primary-n);
    border-color: var(--primary-n);

    .page-link {
      color: var(--primary-n);
      border-color: var(--primary-n);
    }

    .active .page-link {
      background-color: var(--primary-n);
      border-color: var(--primary-n);
    }
  }
}

/* ======= Consolidation API ====== */
.consolidation-card {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  & .consolidation-selected-api {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 17px;

    & .name {
      font-size: 16px;
    }

    & .remove-icon {
      width: 14px;
      cursor: pointer;
      svg {
        height: 12px;
        width: 12px;
        * {
          stroke: $secondary;
          fill: $secondary;
        }
      }
    }
  }
}

.account-numbers {
  .tags-input {
    border-color: #e0eceb;

    .tag {
      background-color: var(--primary-n);
      border: none;
      border-radius: 5px;

      .remove {
        width: 16px;
        height: 16px !important;
        margin-top: 2px;
        margin-left: 7px;
        margin-bottom: 3px;
      }

      button.remove::after {
        content: 'X';
        color: var(--primary-n);
      }
    }
  }
  .error-text {
    color: var(--primary-n);
  }
}

.show-api-wrap {
  height: 400px;
  overflow-y: scroll;
}

//scrollbar styles
::-webkit-scrollbar {
  width: 12px;
  margin-top: 15px;
  height: 14px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #88898a7a;
  border-radius: 6px;
  background: #f7655228;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-n);
  border-radius: 6px;
}

.ant-popover-inner {
  border-radius: 8px !important;
}

/* ===============================
    END settings page
================================*/
