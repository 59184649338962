@import '../base/mixins';

.user-controls {
  display: flex;
  align-items: center;
  margin-top: 15px;

  @include rwd(992) {
    margin-top: 0;
  }

  .new-activity {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .has-notification {
    .new-activity {
      opacity: 1;
    }
  }

  .btn {
    margin-right: 6px;
    border-radius: 10px;

    svg path {
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      background-color: var(--light-bg);

      svg path {
        stroke: var(--primary-n) !important;
        //fill: var(--primary-n) !important;
      }

      // svg
    }

    // btn hover
  }
  @keyframes rotation {
    from {
      transform: rotate(359deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  .rotate-sync-icon-wrap {
    .rotate-sync-icon {
      svg {
        fill: rgba(33, 131, 123, 1);
        animation: rotation 2s infinite linear;
      }
    }
  }
}

.user {
  .dropdown-toggle {
    display: flex;
  }

  & .dropdown-menu.show {
    width: calc(100% - 6px);
  }

  .dropdown-divider {
    margin: 0;
  }

  .dropdown-menu {
    padding: 0;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 3px !important;
  }

  .dropdown-item-text {
    padding: 0.5rem 1.5rem;
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: var(--light-bg);
  }
}

.user-img {
  max-width: 35px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: inline-block;

  img {
    width: 35px;
    height: 35px;
    overflow: hidden;
    object-fit: cover;
    border-radius: 50%;
  }

  .initials {
    min-height: 36px;
    min-width: 36px;
    border-radius: 50%;
    background-color: #ffbebe;
    color: #2f2f2f;
    font-weight: 900;

    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

.user-name {
  font-weight: 500;
}

.sync-dropdown {
  width: 330px;
  border-radius: 7px;
  .sync-progressbar {
    padding-top: 16px;

    .ant-progress {
      .ant-progress-bg {
        height: 14px !important;
      }
    }

    .api-name {
      font-weight: 600;
      font-size: 16px;
      padding-bottom: 12px;
    }

    .sync-complete-noti {
      background-color: rgba(51, 208, 103, 0.15);
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 13.5px;
      border-radius: 4px;

      span {
        margin-left: 10px;
      }
    }
  }
}

.before-sync-wrap {
  width: 280px;
  .bsw-date {
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 12px;
  }
  .bsw-desc {
    color: #727272;
    font-size: 14px;
  }
}
