@import '../base/mixins';

// --------- logo
.brand {
  background: rgba(#ffffff, 0.06);
  border-radius: 10px;
  padding: 20px;
}

.triplex-main {
  @include lg {
    min-height: 100vh;
  }

  & > .row,
  .row.stre {
    @include lg {
      min-height: 100vh;
    }
    overflow: hidden;
  }

  .col-left {
    box-shadow: 0 6px 20px rgba(43, 154, 156, 0.06);
    background: #fff;
    border-radius: 20px 20px 0 0;
    padding: 40px 30px;
    position: relative;
    margin-top: 35px;

    @include lg {
      padding: 50px;
      margin-left: -15px;
      margin-top: 0;
      border-radius: 0 40px 40px 0;
    }
  }

  .triplex-signup-form-bg {
    max-width: 400px;
    margin: 0 auto;
    @include lg {
      width: 100%;
    }
    //min-height: 100vh;

    .btn-primary {
      width: 100%;
    }
  }
}

// ------- slider content
.slide-content-wrap {
  overflow: hidden;

  img {
    display: block;
    max-width: 250px;
    margin: 0 auto;
    width: auto;
    @include lg {
      max-width: 100%;
    }
  }

  h3 {
    margin-top: 25px;
  }

  p {
    max-width: 380px;
    margin: 20px auto;
  }
}

#splide01-slide01,
#splide01-slide02 {
  .slide-content-wrap {
    h3 {
      margin-top: -15px;
    }
  }
}

// --------- slider dots
.splide__pagination {
  .splide__pagination__page {
    margin: 3px 10px;
    width: 10px;
    height: 6px;
    background: #ffffff;
    opacity: 0.4;
    box-shadow: 0px 6px 20px rgba(43, 154, 156, 0.4);
    border-radius: 10px;
    transition: all 0.3s ease-in-out;

    &.is-active {
      width: 30px;
      height: 6px;
      opacity: 1;
      border-radius: 10px;
    }
  }
}

/*---- verify account ----*/
.verify-account-loader {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .va-wrap {
    .cool-loading {
      position: relative;
    }
  }
}

.show-password {
  position: relative;
  .anticon {
    position: absolute;
    top: 48px;
    right: 10px;
  }
}

.hide-password {
  max-height: 44px;
}

.required {
  color: red;
}
