/** ---------------------------------------------------
* Global style file required for all pages
* Other parts are imported per react component / page
------------------------------------------------------*/
/*--------------------------------------------
Import Bootstrap and its default variables
---------------------------------------------*/
@import '~bootstrap/scss/bootstrap.scss';
@import '~slick-carousel/slick/slick.css';

/*-------------------------------
  B a s e
-------------------------------*/
@import 'base/fonts';
@import 'base/mixins';
@import 'base/base';

/*--------------------------------------------
Override default variables before the import
---------------------------------------------*/
$primary: #dd2476;
$secondary: #f76552;
$light-border: rgba($primary, 0.1);

$font-family-base: 'Jost', sans-serif;
$font-family-monospace: 'Jost', sans-serif;
$font-family-sans-serif: 'Jost', sans-serif;
$btn-font-family: 'Jost', sans-serif;

/*-------------------------------
  B a s e - l i n k s
-------------------------------*/
a {
  color: $secondary;
}
/*-------------------------------
  C o m p o n e n t s
-------------------------------*/
@import 'components/btn';
@import 'components/icon';
@import 'components/divider';
@import 'components/form-elements';
@import 'components/loading';
@import 'components/dropdown';
@import 'components/language-switch';
@import 'components/data-card';
@import 'components/data-table';
@import 'components/image-upload';
@import 'components/datepicker';
@import 'components/create-new-board';
@import 'components/progress-bar';
@import 'components/user-invite';
@import 'components/search';
@import 'components/custom-widget';
@import 'components/download-widget-modal';
@import 'components/chart-widget';
@import 'components/chart-of-account';
@import 'components/consolidation';
@import 'components/api-accounts';
@import 'components/ai';

/*-------------------------------
  S e c t i o n s
-------------------------------*/
@import 'sections/sidebar';
@import 'sections/dashboard-content';
@import 'sections/app-controls';
@import 'sections/filters';
@import 'sections/user-controls';
@import 'sections/search-simple';
/*-------------------------------
  P a g e s
-------------------------------*/
@import 'pages/login';
@import 'pages/login-signup';
@import 'pages/dashboard';
@import 'pages/cashflow-full';
@import 'pages/settings';
@import 'pages/public-dashboard';
@import 'pages/personal-settings';
@import 'pages/tripletex-integration';
