.public-dashboard-container {
  padding: 50px 20px 50px 100px;
  background: #f9f9fb;

  .public-dashboard-wrap {
    transition: margin 0.5s ease-in-out;
  }

  .dashboard-content {
    width: 100%;
    margin-left: 0px;
    overflow-x: clip;

    .droppable-ground {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      // margin-left: -15px;
      margin-bottom: -30px;

      .widget-items {
        // margin-left: 30px;
        // margin-bottom: 30px;
        // width: calc(100% - 30px);
        height: auto;
        flex-grow: 1;
      }
    }

    .react-grid-item {
      cursor: auto;
    }
  }
}
