.group-button {
  background: rgba(247, 101, 82, 0.06) !important;

  .add-api-btn {
    border-radius: 6px 0px 0px 6px;
    &:hover {
      border: 1px solid #f76552 !important;
      background: rgba(247, 101, 82, 0.06) !important;
      color: #f76552;
    }
  }

  .add-group-btn {
    border-radius: 0px 6px 6px 0px;
    &:hover {
      border: 1px solid #f76552 !important;
      background: rgba(247, 101, 82, 0.06) !important;
      color: #f76552;
    }
  }
}

.api-account-title {
  font-weight: 800;
  font-size: 18px;
  line-height: 18px;
  color: #0d1333;
  letter-spacing: -0.124898px;
}

.selected-api-group-btn {
  color: var(--primary-n);
  background: rgba(247, 101, 82, 0.06);
  border: 1px solid var(--primary-n);
  box-sizing: border-box;
  line-height: 27px;
  padding: 6px 12px;
  border-radius: 5px;
  box-shadow: 0px 6px 20px rgba(43, 154, 156, 0);
  transition: box-shadow 0.3s ease-in-out;
  height: 44px;

  &:hover {
    path {
      stroke: #fff;
      fill: #fff;
    }
  }
}

.unselected-api-group-btn {
  color: #2d2d2d;
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  line-height: 27px;
  border-radius: 5px;
  padding: 6px 12px;
  box-shadow: 0px 6px 20px rgba(43, 154, 156, 0);
  transition: box-shadow 0.3s ease-in-out;
  height: 44px;
}

.add-api-btn {
  border-radius: 6px 0px 0px 6px;
}

.add-group-btn {
  border-radius: 0px 6px 6px 0px;
}

.info-icon-password {
  position: relative;
  .anticon {
    position: absolute;
    top: 18px;
    right: 10px;
  }
}

.info-icon-api-key {
  position: relative;
  .anticon {
    position: absolute;
    bottom: 6px;
    left: 2px;
  }
}

.info-icon-api-companies {
  position: relative;
  left: 110px;
}