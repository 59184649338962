.page-dashboard {
  background: #f9f9fb;
  box-shadow: 0px -10px 30px rgba(188, 196, 208, 0.2);
  display: flex;
  flex-wrap: wrap;
}

.no-widget-to-show {
  width: 100%;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .title {
    margin-top: 20px;
    font-weight: bold;
    font-size: 18px;
  }

  .add-page-icon {
    width: 14px;
    margin-top: -2px;
    path {
      stroke: var(--primary-n);
      fill: var(--primary-n);
    }
  }

  .add-page-icon-new {
    width: 14px;
    margin-top: -2px;
  }

  .btn-outline-light:hover {
    path {
      stroke: #fff;
      fill: #fff;
    }
  }
}
