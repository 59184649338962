@import '../base/mixins';

.dashboard-content {
  width: calc(100% - 80px);
  margin-left: 80px;
  padding: 0px 0px 43px 0px;
  height: auto;
  // min-height: 1000px;
  overflow-x: clip;
  //background-color: #f9f9f9;

  -webkit-transition: margin-left 0.5s ease-in-out;
  -moz-transition: margin-left 0.5s ease-in-out;
  -o-transition: margin-left 0.5s ease-in-out;
  transition: margin 0.5s ease-in-out;

  .top-bar {
    padding: 20px 30px;
    background-color: #f0f6f6;
    .top-bar-contents {
      display: flex;
      justify-content: space-between;

      .tbc-left {
        display: flex;
        justify-content: flex-start;

        .board-dropdwon {
          position: relative;
          width: 250px;
          .btn-secondary-outline {
            width: 250px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .board-name {
              display: flex;
              align-items: center;
              overflow: hidden;
              width: 180px;

              .board-img {
                max-width: 30px;
                min-width: 30px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: rgba(47, 169, 218, 0.671);
                padding: 1px;
                border: 3px solid rgba(58, 150, 211, 0.815);
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
              }
            }
          }
          .add-page-icon {
            width: 14px;
            margin-top: -2px;
            path {
              stroke: var(--primary-n);
              fill: var(--primary-n);
            }
          }
          .btn-outline-light:hover {
            path {
              stroke: #fff;
              fill: #fff;
            }
          }

          .board-dropdown-content {
            transition: 1s ease-in-out;
            width: 320px;
            background: #ffffff;
            border: 1px solid rgba(114, 114, 114, 0.14);
            box-sizing: border-box;
            box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.04);
            border-radius: 6px;
            padding: 10px;
            position: absolute;
            z-index: 3;

            .bdc-board-list-wrap {
              max-height: 600px;
              overflow-y: auto;
              overflow-x: hidden;
            }
            ::-webkit-scrollbar {
              width: 8px;
              margin-top: 15px;
              height: 14px;
            }

            .bdc-add-page {
              padding-bottom: 13px;
              border-bottom: 1px solid #e8e8e8;
              margin-bottom: 10px;

              .btn-outline-light {
                padding: 8px 10px;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
              }
            }

            .bdc-board-list {
              .bdc-board-list-item-name {
                width: 100%;
                padding: 7px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 1px solid transparent;
                line-height: 16px;

                .board-img {
                  max-width: 28px;
                  min-width: 28px;
                  width: 28px;
                  height: 28px;
                  font-size: 14px;
                  border-radius: 50%;
                  background: rgba(47, 169, 218, 0.671);
                  padding: 1px;
                  border: 3px solid rgba(58, 150, 211, 0.815);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #fff;
                }

                .board-name {
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 14px;
                }

                .child-board-img {
                  max-width: 24px;
                  min-width: 24px;
                  width: 24px;
                  height: 24px;
                  font-size: 12px;
                  border-radius: 50%;
                  background: rgba(47, 169, 218, 0.671);
                  padding: 1px;
                  border: 3px solid rgba(58, 150, 211, 0.815);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #fff;
                }

                .child-board-name {
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 14px;
                }

                &:hover {
                  background: linear-gradient(0deg, #fff6f5, #fff6f5),
                    linear-gradient(217.94deg, #f76552 1.68%, #dd2476 237.76%);
                  border: 1px solid rgba(247, 101, 82, 0.2);
                  box-sizing: border-box;
                  border-radius: 5px;
                  transition: 1s ease-in-out;
                }

                .bdc-hover {
                  width: 170px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  min-width: 170px;
                  margin-right: 10px;
                }

                .bdc-hover:hover {
                  cursor: pointer;
                }
              }

              .bdc-board-list-nested-item-name {
                width: 100%;
                padding: 7px;
                display: flex;
                align-items: center;
                // justify-content: space-around;
                border: 1px solid transparent;
                line-height: 16px;

                .board-img {
                  max-width: 28px;
                  min-width: 28px;
                  width: 28px;
                  height: 28px;
                  font-size: 14px;
                  border-radius: 50%;
                  background: rgba(47, 169, 218, 0.671);
                  padding: 1px;
                  border: 3px solid rgba(58, 150, 211, 0.815);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #fff;
                }

                .board-name {
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 14px;
                }

                .child-board-img {
                  max-width: 24px;
                  min-width: 24px;
                  width: 24px;
                  height: 24px;
                  font-size: 12px;
                  border-radius: 50%;
                  background: rgba(47, 169, 218, 0.671);
                  padding: 1px;
                  border: 3px solid rgba(58, 150, 211, 0.815);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #fff;
                }

                .board-name {
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 14px;
                }

                &:hover {
                  background: linear-gradient(0deg, #fff6f5, #fff6f5),
                    linear-gradient(217.94deg, #f76552 1.68%, #dd2476 237.76%);
                  border: 1px solid rgba(247, 101, 82, 0.2);
                  box-sizing: border-box;
                  border-radius: 5px;
                  transition: 1s ease-in-out;
                }

                .bdc-hover {
                  width: 170px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  min-width: 170px;
                  margin-right: 10px;
                }

                .bdc-hover:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
      .tbc-right {
        display: flex;
        justify-content: flex-end;
      }
    }

    @media (max-width: 992px) {
      .top-bar-contents {
        flex-direction: column;
        align-items: center;

        .tbc-left {
          justify-content: center;
        }
        .tbc-right {
          justify-content: center;
        }
      }
    }

    // @include rwd(1650) {
    //   justify-content: space-between;
    // }
  }

  .ai-icon-wrap {
    position: fixed;
    bottom: 40px;
    background-color: var(--primary-n);
    color: #fff;
    padding: 14px 22px;
    border-radius: 80px;
    right: 40px;
    filter: drop-shadow(0px 6px 18px rgba(0, 0, 0, 0.25));
    cursor: pointer;
    z-index: 999;
  }
}

.top-bar-group-title {
  font-size: 14px;
  font-weight: 700;
  padding-top: 14px;
  line-height: 16.1px;
  color: #2d2d2d;
}

.top-bar-heading-section {
  display: flex;
  justify-content: space-between;
}

.board-search-btn {
  input {
    width: auto;
  }

  button {
    height: 44px;
  }

  .search-wrap {
    // width: 250px !important;
    margin-left: 0px;
  }
}
.cursor-pointer {
  cursor: pointer;
}
