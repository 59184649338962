.create-new-board-modal {
  width: 360px !important;
  .ant-modal-close-icon {
    border-radius: 50%;
    background-color: lightgrey;
    padding: 5px;
    vertical-align: 2px !important;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    border-radius: 10px 10px 0 0;
  }
  .create-new-board-modal-content {
    .cnbm-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .add-page-icon {
        width: 14px;
        margin-top: -5px;
        path {
          stroke: var(--primary-n);
          fill: var(--primary-n);
        }
      }

      .btn-outline-light:hover {
        path {
          stroke: #fff;
          fill: #fff;
        }
      }
    }
  }
  .modal-content {
    border-radius: 6px;
    border: none;
  }

  .modal-body {
    padding: 20px;
    border: 0;

    .form-group:last-child {
      margin-bottom: 0;
    }
  }

  .ant-modal-footer {
    border: 0;
    justify-content: flex-start;
    padding: 20px 20px 30px 20px;

    .btn {
      padding: 13px 20px;
    }

    .btn-primary {
      background: #f76552;
      box-shadow: 0px 6px 20px rgba(247, 101, 82, 0.3);
      border-radius: 8px;
      font-size: 14px;
    }

    .btn-outline-primary {
      border: 1px solid rgba(247, 101, 82, 0.3);
      border-radius: 8px;
      font-weight: bold;
      line-height: 27px;
      font-size: 14px;
      text-align: center;
      letter-spacing: -0.124898px;
      color: #f76552;
      &:hover {
        color: #fff;
        background-color: #f76552;
      }
    }
  }

  .cnbm-delete-btn-wrap {
    text-align: center;
    padding: 20px 0;
    background: rgba(226, 226, 226, 0.35);
    margin: -24px;
    margin-top: 24px;
    border-radius: 0 0 10px 10px;
  }

  .cnbm-delete-comfirm {
    text-align: center;

    .delete-btn-wrap {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .add-page-icon {
        width: 14px;
        margin-top: -2px;
        path {
          stroke: var(--primary-n);
          fill: var(--primary-n);
        }
      }
      .btn-outline-light:hover {
        path {
          stroke: #fff;
          fill: #fff;
        }
      }
    }
  }
} // end modal styles

// ------ start selected items styles
.selected-api-apiAccounts {
  padding: 20px 0 0 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .selected-api {
    background: #f2f2f2;
    border-radius: 8px;
    margin: 5px;
    font-size: 14px;
    display: inline-block;
    overflow: hidden;

    &:hover {
      background-color: rgba($secondary, 0.2);
      color: $secondary;
      svg {
        * {
          stroke: $secondary;
          fill: $secondary;
        }
      }
    }
  }

  .name {
    padding: 7px 12px 7px 12px;
    display: inline-block;
  }

  .remove-icon {
    padding: 10px;
    padding-left: 0;
    display: inline-block;
    cursor: pointer;

    svg {
      height: 12px;
      width: 12px;
    }
  }
}

// ------ start api triggers styles
.board-api-acc-dd-trigger {
  position: relative;
  input {
    cursor: pointer;
  }

  .dropdown-icon {
    position: absolute;
    right: 12px;
    top: 30%;
    transition: ease-in 0.2s;
  }
}

.board-api-acc-dd {
  margin-top: 20px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  height: 300px;
  overflow-y: auto;
  padding: 10px;
  position: absolute;
  width: calc(100% - 40px);
  border: 1px solid rgba(45, 45, 45, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.04);

  .board-api-account {
    padding: 14px;
    // border-bottom: 1px solid rgba(#2d2d2d, 0.06);
    border: 1px solid rgba(45, 45, 45, 0.06);
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;

    &:hover,
    &.selected {
      background-color: rgba($primary, 0.03);
      border-color: var(--primary-n);
      border-radius: 8px;
      .name {
        color: $secondary;
      }
      svg * {
        stroke: $secondary;
      }
    }

    //.name {
    //  cursor: pointer;
    //}
    //
    //.status-icon:hover {
    //  cursor: pointer;
    //}

    // &:last-child {
    //   border-bottom: 0;
    // }
  }
}
