/* ------ START responsive breakpoints ------- */
$screen-xs-min: 576px; // mobiles
$screen-sm-min: 768px; // Small tablets (portrait view)
$screen-md-min: 1024px; // Small tablets (portrait view)
$screen-lg-min: 1333px; // Tablets and small desktops
$screen-xl-min: 1440px; // Large tablets and desktops

// mobile devices
@mixin xs {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
}

// tablet devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Custom devices
@mixin rwd($screen) {
  @media (min-width: $screen+"px") {
    @content;
  }
}

/* ------ END responsive breakpoints ------- */