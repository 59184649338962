.dropdown-item {
  cursor: pointer;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
  color: initial;
}

.report-dropdown {
  .dropdown-toggle {
    padding-right: 1rem !important;
    border-radius: 4px !important;
    position: relative;
    .hover-text {
      position: absolute;
      visibility: hidden;
    }
  }
  .dropdown-toggle::after {
    background: none;
  }
  .dropdown-toggle:focus {
    outline: none;
  }
}

//// drop down for widgets
.widget-modal {
  width: 800px !important;

  .ant-modal-close-icon {
    border-radius: 50%;
    background-color: lightgrey;
    padding: 5px;
    vertical-align: 2px !important;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    border-radius: 10px 10px 0 0;
  }
  .widget-dropdown-wrap {
    // margin-top: 30px;
    .widget-dropdown-container {
      display: flex;
      width: 100%;
      .widget-dropdown-item {
        list-style: none;
        width: 180px;
        padding: 16px 25px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 6px;
        background: #0d1333;
        color: #fff;
      }

      .widget-items-container {
        width: 100%;
        padding: 0 16px;
        overflow-y: auto;

        .widget-childs {
          padding: 14px 16px;
          border-radius: 6px;
          background-color: #f7f7f7;
          margin-bottom: 10px;

          &:hover {
            background-color: var(--light-bg);
            color: var(--primary-n);
            cursor: pointer;
          }

          img {
            transition-duration: 0.2s;
            transition-property: transform;
            transition-timing-function: ease-out;
          }

          .widget {
            display: flex;
            align-items: center;
          }

          .widget-info-icon {
            margin-left: 5px;
            margin-top: -10px;
            width: 13px;
          }
        }

        .widget-images-wrap {
          display: flex;
          justify-content: space-between;
          .widget-images {
            padding: 15px;
            position: relative;
            // box-sizing: border-box;
            // box-shadow: 0px 14px 20px rgba(34, 44, 60, 0.02);
            // border-radius: 6px;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            // img {
            //   cursor: pointer;
            //   width: 70%;
            // }
            .image-hover {
              position: relative;
              margin-top: 10px;
              .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 6px;
                background: rgba(0, 0, 0, 0);
                transition: background 0.5s ease;
              }
            }
          }
          .button-wrap {
            position: absolute;
            width: 100%;
            left: 0;
            top: 45%;
            text-align: center;
            opacity: 0;
            transition: opacity 0.35s ease;

            .widget-btn {
              border: 1px solid var(--primary-n);
              background: linear-gradient(0deg, #fff6f5, #fff6f5),
                linear-gradient(217.94deg, #f76552 1.68%, #dd2476 237.76%);
              border-radius: 6px;
            }
            .widget-btn:hover {
              background: var(--primary-n);
              color: #fff;
            }
          }
          .image-hover:hover .button-wrap {
            opacity: 1;
          }
          .image-hover:hover .overlay {
            display: block;
            background: rgba(0, 0, 0, 0.3);
          }
        }
      }
    }

    .widget-title {
      font-size: 18px;
    }
  }

  .arrow-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: relative;
    overflow: hidden;
    margin-left: 12px;
    border-bottom: 10px solid white;
  }

  .arrow-up:after {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    background: rgb(255, 255, 255);
    transform: rotate(45deg);
    top: 75px;
    left: 25px;
    box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.25);
  }

  .widget-dropdown-item:hover {
    background-color: var(--light-bg);
    color: var(--primary-n);
  }
}

.widget-download-modal {
  width: 450px !important;
}

// dropdown widgets action
.widget-dropdown {
  margin-left: -70px;
  width: 166px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  padding: 8px 0;
  // border: 1px solid var(--border-color);
  // box-shadow: 0 5px 12px 0px rgba(0, 0, 0, 0.06), 0 5px 6px -2px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(188, 196, 208, 0.2);
  box-shadow: 0 8px 24px rgba(188, 196, 208, 0.12);

  span {
    border-bottom: 2px solid var(--border-color);
  }

  .dropdown-item-widget {
    background-color: #fff;
    padding: 8px 16px;
    border-radius: 9px;
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    text-decoration: none;

    span {
      border: none;
    }

    .btn-text {
      margin-left: 10px;
      text-decoration: none;
    }

    &:hover {
      color: var(--primary-n);
    }
  }
}

//share board
.dropdown-wrap {
  // margin-top: 30px;
  background-color: #fff;
  border-radius: 6px;
  padding-left: 0;
  box-shadow: 0 5px 12px 0px rgba(0, 0, 0, 0.06), 0 5px 6px -2px rgba(0, 0, 0, 0.05);

  .share-borad {
    width: 478px;
    padding: 20px;

    .ant-input-affix-wrapper {
      border: 1px solid #e0eceb;
      color: var(--light-text);
      border-radius: 6px;

      input[value] {
        color: var(--text);
      }
    }

    .share-board-select {
      .ant-select-selector {
        background: #ffffff;
        border: 1px solid #e0eceb !important;
        box-sizing: border-box;
        min-height: 50px;
        height: auto;
        padding-left: 9px;

        .ant-select-selection-item {
          height: 34px;
        }
      }
    }

    .ant-input-affix-wrapper-focused {
      box-shadow: 0 0 0 1px var(--primary-n);
    }

    .btn {
      padding: 10px 20px;
      border-radius: 6px;
    }
    .public-link-wrap {
      svg {
        width: 14px;
        height: 14px;
        margin-right: 8px;
        margin-top: -3px;
        transform: rotate(-5deg);
      }
      .public-board-link {
        display: flex;
        .ant-input {
          border-radius: 6px 0 0 6px;
          border-color: #e0eceb !important;
          border-right: none;
        }
        .ant-input:hover {
          border-color: #e0eceb !important;
        }
        .ant-input:focus,
        .ant-input-focused {
          box-shadow: none;
          border: 1px solid #e0eceb;
          border-right: none;
        }
        .btn {
          width: 115px;
          border-radius: 0 6px 6px 0;
          border: 1px solid #e0eceb;
        }

        .btn:hover {
          border: 1px solid var(--primary-n);
        }
      }

      .show-or {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 24px;

        .or {
          padding: 0 25px;
        }
        .line {
          width: 200px;
          border-bottom: 1px solid var(--border-color);
        }
      }

      .form-group {
        .form-control {
          color: var(--light);
        }
      }

      .show-all-emails-wrap {
        display: flex;
        flex-wrap: wrap;

        .email {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 7px 10px;
          background-color: var(--primary-n);
          color: white;
          border-radius: 6px;
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
    }

    .generate-link-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      background-color: var(--light-bg);
      border-radius: 6px;

      .ant-switch-checked {
        background-color: var(--primary-n);
      }
    }

    .pages-checked {
      padding-top: 20px;

      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-wrapper:hover .ant-checkbox-inner {
        border-color: var(--primary-n);
      }

      .ant-checkbox-input {
        display: none;
      }

      .ant-checkbox-group {
        display: flex;
        justify-content: space-between;
      }

      .ant-checkbox + span {
        padding-right: 0;
      }
      .ant-checkbox-checked {
        background-color: var(--primary-n);
        .ant-checkbox-inner {
          border-color: var(--primary-n);
          background-color: var(--primary-n);
        }
      }
      .ant-checkbox-checked::after {
        border-color: var(--primary-n);
      }
    }

    //custom radio button
    .widget-type-radio {
      margin-top: 20px;
      justify-content: space-around;
    }
  }
}

//profile dropdown
.profile-dropdown-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
  height: 44px;
  padding: 0 10px;

  &:hover {
    color: var(--primary-n);
    border-color: var(--primary-n);
    border-radius: 6px;
    background: linear-gradient(0deg, #fff6f5, #fff6f5), linear-gradient(217.94deg, #f76552 1.68%, #dd2476 237.76%);
  }
}

.profile-dropdown {
  width: 300px;
  padding: 20px;

  .profile-image {
    display: flex;
    align-items: center;

    .pd-img-wrap {
      border-radius: 20%;
      height: 40px;
      width: 40px;
      .initials {
        height: 40px;
        width: 40px;
        border-radius: 20%;
      }
    }

    .profile-name {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .profile-menu {
    a {
      text-decoration: none;
      color: var(--text);
    }

    .pm-item {
      display: flex;
      align-items: center;
      padding: 10px 0;

      .pm-item-icons {
        background: #edededc5;
        border-radius: 5px;
        width: 26px;
        height: 26px;
        max-width: 26px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 14px;
          height: 14px;
        }
      }
    }

    .pm-logout {
      cursor: pointer;
    }
  }
}

.sidebar-widget {
  // width: 500px;
}

.add-widget {
  display: flex;
}

.widget-hover-group {
  position: relative;
  &:hover .widget-hover-child {
    visibility: visible;
    opacity: 1;
  }
  .widget-hover-child {
    margin-bottom: 8px;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease-in-out;

    span img {
      cursor: pointer;
    }
  }
}
.ant-tabs-tab-active {
  .ant-tabs-tab-btn {
    color: #f76552 !important;
  }
}
.ant-tabs-tab:hover {
  color: #f76552 !important;
}
.ant-tabs-ink-bar {
  background-color: #f76552 !important;
}

.ant-radio-wrapper-checked {
  color: #f76552 !important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #f76552 !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #f76552;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #f76552;
}

.btn-left-bordered {
  padding: 10px 14px !important;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  margin-bottom: 8px !important;
  width: 100% !important;
  display: flex !important;
  justify-content: start !important;
  align-items: center !important;
  border-left: 2px solid transparent !important;
  color: #282828 !important;
  height: auto !important;

  svg {
    margin-right: 8px;
    path {
      stroke: #282828;
    }
  }

  &:hover {
    background-color: rgba(247, 101, 82, 0.06) !important;
    border-left: 2px solid #f76552 !important;
    color: #f76552 !important;
    svg path {
      stroke: #f76552 !important;
    }
  }
}

.pop-conf-no-pad .ant-popover-inner-content {
  padding: 0 !important;
}
