.btn {
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  &.btn-primary {
    background: linear-gradient(231.06deg, #f76552 8.99%, #dd2476 131.61%);
    box-shadow: 0px 4px 12px rgba(239, 80, 95, 0.3);
    border: none;
    outline: none;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
    letter-spacing: 1px;
  }

  &.btn-lg {
    padding: 1rem 1rem;
  }
}
