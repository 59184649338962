.ai-modal {
  .ant-modal-close-icon {
    border-radius: 50%;
    background-color: lightgrey;
    padding: 5px;
    vertical-align: 2px !important;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    border-radius: 10px 10px 0 0;
    border-bottom: none;
    .ant-modal-title {
      text-align: center;
      font-weight: 600;
      font-size: 18px;
    }
  }

  .ai-body {
    transition: transform 0.35s ease-in-out;
    .ai-slider {
      max-height: 32px;
      overflow: hidden;
      .slick-slider {
        display: flex;
        align-items: center;

        .slick-list {
          .slick-slide {
            background: #f0f6f6;
            border-radius: 4px;
            padding: 4px 8px;
            margin-right: 12px;

            .ai-slider-data {
              font-size: 12px;
              line-height: 16px;
              cursor: pointer;
            }
          }
        }

        .slick-next {
          margin-left: -20px;
          z-index: 9;
          background: transparent;
          filter: drop-shadow(0px 6px 18px rgba(0, 0, 0, 0.25));
          cursor: pointer;
        }

        .slick-prev {
          transform: rotate(-180deg);
          margin-right: -20px;
          z-index: 9;
          background: transparent;
          filter: drop-shadow(0px 6px 18px rgba(0, 0, 0, 0.25));
          cursor: pointer;
        }
      }
    }
    .search-data-wrap {
      margin-top: 26px;
      display: flex;
      align-items: center;
      padding: 8px;
      border: 1px solid #e1e1e1;
      border-radius: 6px;

      .ant-spin-container {
        .btn-primary-new {
          width: 95px;
        }
      }

      .ant-input-affix-wrapper {
        border: none;
        box-shadow: none;
        outline: none;
        height: 32px;

        input {
          min-height: 32px;
          margin-top: -4px;
          margin-left: 8px;
          color: #2d2d2dd3;
        }

        .ant-input-prefix {
          svg {
            path {
              stroke: var(--light-text);
            }
          }
        }
      }

      .btn-primary-new {
        height: 32px;
      }
    }

    .search-input-info {
      .word-count {
        color: red;
        width: 100%;
      }
      .char-count {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        span {
          margin-left: 5px;
        }
      }
    }

    .recent-searches {
      margin-top: 24px;
      height: 255px;
      overflow-y: auto;
      .rs-title {
        font-weight: 600;
        font-size: 14px;
      }
      .rs-data {
        margin-top: 18px;
        cursor: pointer;
      }
    }
    .see-all {
      cursor: pointer;
      color: var(--primary-n);
      margin-top: 16px;
    }

    .ai-result-page {
      background: rgba(45, 45, 45, 0.03);
      margin: 0 -24px;
      padding: 24px;

      .res-ques {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 24px;

        .edit {
          cursor: pointer;
        }

        .ques-title {
          font-weight: 500;
          font-size: 18px;
        }
      }
      .res-div-wrap {
        background: #ffffff;
        border: 1px solid rgba(114, 114, 114, 0.14);
        border-radius: 8px;
        padding: 20px;
        min-height: 300px;
        display: grid;
        place-items: center;
        overflow: auto;
        h1,
        h2,
        h3 {
          font-size: 20px;
        }
      }

      .add-to-board {
        text-align: center;
        padding-top: 20px;
      }
    }

    .feedback-wrap {
      padding: 24px 0 4px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .feedback-title {
        font-size: 18px;
        font-weight: 500;
        svg {
          margin-bottom: 2px;
        }
      }

      .btn-wrap {
        .btn-secondary-outline {
          margin-left: 12px;
          min-width: 84px;
        }
      }
    }

    .ai-note {
      background: #e0eceb;
      border-radius: 6px;
      padding: 8px 60px;
      text-align: center;
      font-size: 16px;

      span {
        color: var(--primary-n);
        font-weight: 600;
        font-size: 16px;
      }
    }

    .feedback-msg-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-top: 20px;

      .ai-border {
        width: 100%;
        margin: 20px 0;
        border-top: 1px solid #ebebeb;
      }
      .feedback-msg {
        background: rgba(51, 208, 103, 0.1);
        border-radius: 6px;
        color: #33d067;
        font-weight: 600;
        font-size: 16px;
        padding: 8px 12px;
        width: fit-content;
      }

      .feedback-desc {
        margin-top: 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        max-width: 500px;
        text-align: center;
      }
    }
  }
}
