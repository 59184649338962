.data-table {
  .table {
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: 30px;

    th,
    td {
      padding: 20px;
    }

    tbody th {
      font-weight: normal;
      font-size: 16px;
    }

    tfoot {
      tr {
        td {
          border-right: 1px solid #e8e8e8;
        }
      }
    }

    thead {
      th {
        border: none;
        border-right: 1px solid #e8e8e8;
        padding-bottom: 20px;
        filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.1));
      }
    }

    td {
      font-size: 14px;
    }
  }

  .month-name {
    opacity: 0;
  }

  [data-tid='first-table'] {
    .month-name {
      opacity: 1;
    }
  }
}
