.btn-lang {
  background: rgba(254, 238, 236, 0.3);
  border: 1px solid rgba(254, 238, 236, 0.7);
  border-radius: 6px;
  padding: 12px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  width: 88px;
  position: absolute;
  height: 30px;

  //&:hover {
  //  background: rgba(247, 101, 82, 0.05);
  //}

  &:hover,
  &:focus,
  &:active {
    outline: none;
    border: none;
    box-shadow: 0px 10px 22px rgba(45, 45, 45, 0.06);
  }

  &.active {
    opacity: 1;
    position: relative;
  }

  .text {
    margin-right: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.124898px;
    color: #2d2d2d;
  }

  .lang-icon {
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 20px;
    min-width: 20px;
    height: 20px;
    width: 20px;
    padding: 0;
    overflow: hidden;

    svg {
      height: 20px;
      width: 20px;
    }
  }
}
