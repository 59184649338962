@import '~antd/dist/antd.css';

.date-picker-wrap {
  display: flex;
  // margin-bottom: 30px;
  align-items: center;
  align-content: center;
  // padding-right: 60px;

  //antd date-picker
  .ant-picker-clear {
    display: none;
  }

  .ant-picker {
    border-color: #e0eceb;
    border-radius: 6px;
  }
  .ant-picker-input input {
    size: 7;
    text-align: center;
  }
  .ant-picker:hover,
  .ant-picker-focused {
    border-color: var(--light-bg);
    cursor: pointer;
    box-shadow: 0 0 0 2px var(--light-bg);
  }
  .ant-picker-range {
    .ant-picker-active-bar {
      margin-left: 30px !important;
      width: 65px !important;
      background: var(--primary-n);
    }
  }

  .ant-picker-range-separator {
    svg {
      display: none;
    }
    display: block;
    height: 2px;
    border: 0;
    border-top: 2px solid var(--text);
    margin: 1em 0;
    padding: 0;
  }
}

td.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
td.ant-picker-cell-in-view.ant-picker-cell-range-start {
  .ant-picker-cell-inner {
    background: var(--primary-n);
  }
}

td.ant-picker-cell-in-view.ant-picker-cell-range-end {
  .ant-picker-cell-inner {
    background: var(--primary-n);
  }
}

td.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
td.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: var(--light-bg);
}

td.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: var(--light-bg);
}

td.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before {
  background: var(--light-bg);
  .ant-picker-cell-inner {
    color: #fff;
  }
}
td.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
td.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before {
  background: var(--light-bg);
}

.ant-picker-panel > :not(.ant-picker-date-panel) {
  td.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
  td.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: rgba(247, 101, 82, 0.2);
  }
}

//single date picker
.ant-picker-clear {
  display: none;
}

.ant-picker.ant-picker-borderless {
  display: block;
  padding-left: 0;
  margin-top: 5px;
}

td.ant-picker-cell-in-view.ant-picker-cell-selected {
  .ant-picker-cell-inner {
    background: var(--primary-n);
  }
}

td.ant-picker-cell.ant-picker-cell-in-view {
  .ant-picker-cell-inner:hover {
    background: var(--primary-n) !important;
    color: #fff;
  }
}
