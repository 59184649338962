.chart-of-number {
  width: 200px;
  text-align: left !important;
}

.chart-of-name {
  width: 800px;
  max-width: 600px;
  min-width: 500;
  padding-right: 18px;
  text-align: left !important;
}

.chart-of-type {
  max-width: 200px;
  padding-right: 10px;
  width: 600px;
}

.chart-of-disc {
  width: 200px;
}

.chart-of-heading {
  font-weight: bold;
  padding: 0px 15px 15px 0px;
  font-size: 18px;
  line-height: 18.4px;
}

.chart-of-sub-heading {
  font-weight: 400;
  padding: 0px 18px 25px 0px;
  height: 18px;
  font-size: 16px;
  line-height: 18.4px;
}

.chart-of-title {
  font-weight: bold;
  height: 18px;
  font-size: 16px;
  line-height: 18.4px;
}

.chart-of-element {
  font-weight: 500px;
  font-size: 16px;
  height: 18px;
}

.chart-of-dd-list {
  white-space: nowrap;
  font-weight: 500px;
  font-size: 18px;
  line-height: 30.4px;
}

// .chart-of-dd-list:hover {
//   background-color: var(--light-bg);
// }
