@import "../base/mixins";

.filters {
  display: flex;
  align-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 10px;

  @include rwd(1155) {
    margin-top: 0;
  }

  @include rwd(977) {
    margin-bottom: 0;
  }

  select,
  input,
  .btn {
    border: 1px solid rgba(#2D2D2D, .1);
    border-radius: 6px;
    font-weight: 500;
    height: 42px;
    background-color: transparent;
  }

  .filter-item {
    margin: 0 5px;
  }

  .select-board {
    width: auto;
    max-width: 400px;
  }

  .select-date {
    max-width: 400px;
  }

  .select-date-shortcut .btn {
    box-shadow: none;
    outline: none;

    &:hover {
      background-color: #fff;
      border-color: #E0ECEB;
      color: var(--primary-n);
      box-shadow: none;
      outline: none;
    }

    &:focus,
    &:active,
    &:visited {
      box-shadow: none !important;
      outline: none !important;
    }
  }

  .select-date-shortcut .btn.active {
    color: #fff;
    background-color: var(--primary-n);
    // border-color: #E0ECEB;

    &:focus,
    &:active,
    &:visited {
      box-shadow: none;
      outline: none;
    }
  }

}