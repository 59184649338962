.triplex-main {
  //background: #2b9a9c;
  //background: url("/images/login/slider-bg-overlay.png"), linear-gradient(222.2deg, #F76552 -1.14%, #DD2476 137.18%);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
}

//.triplex-main,
//.triplex-signup-form-bg {
//    min-height: 100vh;
//    box-shadow: 0 6px 20px rgba(43, 154, 156, 0.06);
//}
//.triplex-signup-form-bg {
//    background: #fff;
//    border-radius: 0 40px 40px 0;
//    margin-left: -15px;
//    padding: 50px;
//    position: relative;
//}
.sign-up-title {
  align-items: center;
  letter-spacing: -0.124898px;
  color: #19233c;
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: 700;
}

.subtitle {
  opacity: 0.9;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 40px;
}

.form-level,
.subtitle {
  color: #2d2d2d;
}

.form-input {
  border: 1px solid #e0eceb;
  box-sizing: border-box;
  border-radius: 6px;
}

.link-color {
  color: var(--primary);
  text-decoration: underline;
}

.login-text {
  font-style: normal;
  font-weight: 500;
  //font-size: 14px;
  line-height: 28px;
}
