/*============= Widget Styles ==============*/
.droppable-ground {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  // margin-left: -15px;
  margin-bottom: -30px;

  .widget-items {
    // margin-left: 30px;
    // margin-bottom: 30px;
    // width: calc(100% - 30px);
    height: auto;
    flex-grow: 1;
  }

  // @include md {
  //   .widget-items {
  //     width: calc(50% - 30px);
  //     max-width: calc(50% - 30px);
  //   }
  // }

  // @include lg {
  //   .widget-items {
  //     width: calc(1 / 3 * 100% - 30px);
  //     max-width: calc(1 / 3 * 100% - 30px);
  //     // display: inline-block;
  //   }
  //   .custom-widget-items {
  //     width: calc(1 / 6 * 100% - 30px);
  //     max-width: calc(1 / 6 * 100% - 30px);
  //   }
  // }

  .no-widget-title {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    h4 {
      color: var(--light-text);
    }

    svg {
      margin-top: -10px;
      path {
        stroke: var(--light-text);
      }
    }
  }

  .react-grid-layout {
    position: relative;
    transition: height 200ms ease;
    width: 100%;
  }
  .react-grid-item {
    // transition: all 200ms ease;
    // transition-property: left, top;
    text-align: center;
    width: 100%;
    cursor: move;
  }
  .react-grid-item span {
    display: inline-block;
    // padding: 20px 10px;
  }
  .react-grid-item.cssTransforms {
    transition-property: transform;
  }
  .react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
  }

  .react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
  }

  .react-grid-item.react-grid-placeholder {
    background: var(--primary-n);
    opacity: 0.2;
    transition-duration: 100ms;
    border-radius: 8px;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .react-grid-item > .react-resizable-handle {
    display: none;
  }

  .react-grid-item > .react-resizable-handle::after {
    content: '';
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  }
}

/*------------- START Data cart styles -------------*/
.data-cards-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

.data-card {
  background: #ffffff;
  border-radius: 10px;
  height: 100%;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(114, 114, 114, 0.14);

  &__header {
    margin-bottom: 16px;
    padding: 20px 20px 0 20px;
  }

  &__company-name {
    height: 34px;
    padding: 0 20px;
    background: rgba(114, 114, 114, 0.06);
    margin-bottom: 12px;

    .name-wrap-first {
      width: 60%;
      text-align: left;
    }
    .name-wrap {
      width: 40%;
    }
  }

  .lt-border {
    .name-wrap-first {
      width: 58%;
      text-align: left;
    }
    .name-wrap {
      width: 42%;
    }

    @media (min-width: 2000px) {
      .name-wrap {
        width: 41%;
      }
    }
  }

  & .lt-border:last-child {
    border-bottom: none;
  }

  // & .expense-legend {
  // }

  //custom widget
  &__wrap-custom {
    .custom-widget-number {
      font-size: 14px;
      line-height: 30px;
    }
  }

  &__options {
    margin-left: 10px;
    .ant-dropdown {
      margin-right: -40px;
    }

    // &.show,
    // .btn:hover {
    //   svg rect {
    //     fill: var(--primary-n);
    //   }
    // }
  }

  &__name {
    opacity: 0.9;
  }

  &__amount {
    padding-right: 20px;
    border-radius: 80px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;

    span {
      white-space: nowrap;
    }
  }

  &__lt-amount-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;

    .lt-tag {
      color: var(--light-text);
    }

    span {
      white-space: nowrap;
    }
  }

  &__month {
    width: 60px;
    height: 26px;
    border-radius: 10px;
  }

  &__number {
    background-color: rgba(45, 45, 45, 0.06);
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__company {
    text-align: left;
  }

  &__supplier-wrap {
    display: block;
    height: 42vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  & .supplier-table {
    tbody tr {
      display: table;
      width: 100%;
      // table-layout: fixed;
    }
  }

  &__wrap-customer {
    //TODO
    // height: 454px;
    display: flex;
    flex-direction: column;
    // justify-content: space-around;
    padding-left: 20px;
  }

  &__wrap-transaction {
    //TODO
    padding: 0 20px;
    // height: 780px;
    // overflow-y: auto;
  }
  .customer-data {
    height: 30px;
    margin-top: 14px;
  }

  .customer-data:first-child {
    margin-top: 0;
  }

  //scrollbar styles
  ::-webkit-scrollbar {
    width: 8px;
    margin-top: 15px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #88898a7a;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--primary-n);
    border-radius: 6px;
  }

  &--xl {
    width: 100%;
    //width: 68.5%;
    //max-width: 1025px;
    @include lg {
      width: 66%;
    }
    @include xl {
      width: 60%;
    }
  }

  &--md {
    width: 100%;
    //max-width: 500px;
    @include sm {
      width: 48.5%;
    }
    @include lg {
      width: 31.83%;
    }
    @include xl {
      width: 29%;
    }
  }

  &--sm {
    width: 100%;
    //max-width: 310px;
    @include sm {
      width: 48.5%;
    }
    @include lg {
      width: 31.83%;
    }
    @include xl {
      width: 18.5%;
    }
  }

  // data card child elements
  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.002em;
    text-transform: capitalize;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 0;
  }

  &__filter {
    margin-left: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
  }

  // date selector styles
  &__filter-date {
    background-color: var(--primary-n);
    box-shadow: 0px 6px 20px rgba(43, 154, 156, 0.2);
    cursor: pointer;

    &:hover {
      background-color: var(--primary-n);
      border-color: var(--primary-n);
    }

    select {
      cursor: pointer;
      border: none;
      background-color: var(--primary-n);
      border-radius: 5px;
      width: 91px;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      //line-height: 28px;
      color: #fff;
    }

    select:before {
      color: #fff;
    }

    .custom-select {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTE3LDkuMTdhMSwxLDAsMCwwLTEuNDEsMEwxMiwxMi43MSw4LjQ2LDkuMTdhMSwxLDAsMCwwLTEuNDEsMCwxLDEsMCwwLDAsMCwxLjQybDQuMjQsNC4yNGExLDEsMCwwLDAsMS40MiwwTDE3LDEwLjU5QTEsMSwwLDAsMCwxNyw5LjE3WiIvPjwvc3ZnPg==');
      background-size: 20px;
    }
  }

  &__mrr-title {
    // color: #33d067;
    // font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #0d1333;

    .mrr-widget-date-title {
      color: var(--primary-n);
      font-size: 14px;
      font-weight: 500;
    }
  }

  // END date selector styles

  &__filter--active {
    background: rgba(91, 118, 249, 0.04);
    border-color: transparent !important;
  }

  //chart widget styles
  .chart-widget {
    width: 100%;
    // height: 100%;
    // display: flex;
    justify-content: center;
    // align-items: center;
    margin: 0;
  }

  .doughnut-chart {
    width: 100%;
    min-height: 382px;

    // canvas {
    //   max-width: 900px;
    //   max-height: 450px;
    //   margin: auto;
    // }

    .card-filter-btn {
      flex-wrap: wrap;
    }
  }

  .mrr-bar-chart {
    height: 100%;
  }

  //mrr widget
  &--mrr {
    // height: auto;
  }
  .data-card__header--mrr {
    .right {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .card-filter-btn {
      padding-left: 20px;
      .data-card__options.dropdown {
        margin: 0;
      }
      button {
        background: #f9f9fb;
        border-radius: 4px;
        height: 40px;
        width: 40px;
        svg {
          transform: rotate(90deg);
          rect {
            fill: transparent;
          }
        }
      }
    }
  }

  .mrr-w-title {
    font-size: 16px;
    .mrr-title-bold {
      font-size: 24px;
      color: #5b76f9;
      font-weight: 700;
      margin-left: 8px;
    }

    .upArrow {
      svg {
        margin-top: -2px;
      }
    }

    .reverseArrow {
      transform: rotate(-180deg);
      margin-top: -1px;
      svg {
        path {
          fill: #f76552;
        }
      }
    }
  }
  .charts-nav {
    display: flex;
    background: #f7f7f7;
    border-radius: 4px;
    padding: 5px;
  }
  .chart-nav__item {
    display: flex;
    background: transparent;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 4px 10px;
    align-content: center;
    align-items: center;
    cursor: pointer;
    &__icon {
      margin-right: 6px;
      display: flex;
      svg * {
        stroke: #2d2d2d;
      }
    }
    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #2d2d2d;
      padding-top: 2px;
    }

    &.active {
      background: #f76552;
      .chart-nav__item__icon {
        svg * {
          stroke: #fff;
        }
      }
      .chart-nav__item__title {
        color: #fff;
      }
    }
  }
  // chart new bottom legend
  .chart-bottom {
    display: grid;
    grid-gap: 72px;
    grid-template-columns: auto auto auto;
    justify-content: center;
    padding-top: 18px;
    padding-bottom: 24px;
  }
  .chart-bottom__item {
    //display: grid;
    //grid-template-columns: auto auto auto auto;
    //grid-gap: 5px;
    //justify-content: center;
    &__data {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      &__icon {
        cursor: pointer;
        margin-top: -2px;
      }
      &__text {
        margin: 0 10px 0 5px;
        font-style: normal;
        font-size: 12px;
        color: #0d1333;
        font-weight: 500;
        line-height: 23px;
        white-space: nowrap;
      }
    }
    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -6px;
    }
    &__num {
      font-style: normal;
      padding-left: 10px;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      color: #0d1333;
    }
  }
}

.color-dot {
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  max-width: 15px;
  max-height: 15px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
  border: 4px solid #fff;

  &--blue {
    background: #d4f1f4;
    border: 3px solid #fff;
  }

  &--sky {
    background: #75e6da;
    border: 3px solid #fff;
  }

  &--green {
    background: #a0e7e5;
    border: 3px solid #fff;
  }

  &--purple {
    background: #ffaebc;
    border: 3px solid #fff;
  }

  &--orange {
    background: #b4f8c8;
    border: 3px solid #fff;
  }
}

.data-card__filter--active {
  .card-filter-btn__label {
    &--blue {
      color: #d4f1f4;
    }

    &--orange {
      color: #b4f8c8;
    }

    &--green {
      color: #a0e7e5;
    }
    &--purple {
      color: #ffaebc;
    }
    &--sky {
      color: #75e6da;
    }
  }
}

// -------- START data-line-item
.color-box {
  height: 15px;
  width: 15px;

  &--blue {
    background-color: #534fff;
  }

  &--orange {
    background-color: #fdbf5a;
  }

  &--green {
    background-color: #33d067;
  }
}

.data-line-item {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;

  color: #2d2d2d;
  padding: 10px 0;

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #2d2d2d;
    padding: 0 14px;
  }

  &__price {
    font-weight: 500;
    font-size: 16px;
    color: #999fbf;
    margin-left: auto;
  }
}

// -------- END data-line-item

// -------- START sm info cards.
.sm-info-card {
  .num {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
  }

  .foot {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }

  .time-frame {
    color: var(--light-text);
  }

  &--theme-blue {
    .percent-num {
      color: var(--theme-blue);
    }

    .theme-color {
      fill: var(--theme-blue);
    }
  }

  &--theme-lightblue {
    .percent-num {
      color: var(--theme-lightblue);
    }

    .theme-color {
      fill: var(--theme-lightblue);
    }
  }

  &--theme-green {
    .percent-num {
      color: var(--theme-green);
    }

    .theme-color {
      fill: var(--theme-green);
    }
  }

  &--theme-lightgreen {
    .percent-num {
      color: var(--theme-lightgreen);
    }

    .theme-color {
      fill: var(--theme-lightgreen);
    }
  }

  &--theme-teal {
    .percent-num {
      color: var(--theme-teal);
    }

    .theme-color {
      fill: var(--theme-teal);
    }
  }

  &--theme-lightteal {
    .percent-num {
      color: var(--theme-lightteal);
    }

    .theme-color {
      fill: var(--theme-lightteal);
    }
  }
}

// -------- END sm info cards

// -------- START data clients info
.data-clients {
  th,
  td {
    vertical-align: middle;
    padding: 20px 5px;
  }

  .icon--image {
    flex-wrap: wrap;
    text-align: center;
    line-height: 1;
    font-size: 14px;

    span {
      width: 100%;
      font-size: 14px;
    }
  }

  .icon--image:hover {
    background-color: #fff;
    box-shadow: none;
    //box-shadow: 0px 0px 20px 1px rgba(43, 154, 156, 0.2);
    cursor: default;
  }

  // &__img {
  // }
}

.ant-dropdown {
  z-index: 2 !important;
}

// tooltip design
.tooltip {
  position: relative;
  opacity: 1;
  z-index: 1 !important;
  font-family: 'Sofia Pro' !important;
}

.tooltip::before,
.tooltip::after {
  position: absolute;
  // opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
}

.tooltip:hover::before,
.tooltip:hover::after {
  opacity: 1;
  visibility: visible;
}

.tooltip::before {
  content: attr(data-tooltip);
  z-index: 10;
  width: 210px;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  padding: 15px;
}

.tooltip::after {
  content: '';
  width: 0;
  height: 0;
}

.tooltip--top::before,
.tooltip--top::after {
  bottom: 100%;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 15px;
}

.tooltip--top::after {
  margin-bottom: 8px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.7);
}

//.mrr-tooltip {
//  font-size: 16px;
//  .mrr-title-bold {
//    font-size: 24px;
//    color: #5b76f9;
//    font-weight: 700;
//    margin-left: 8px;
//  }
//
//  .upArrow {
//    svg {
//      margin-top: -2px;
//      path {
//        fill: #33d067;
//      }
//    }
//  }
//
//  .reverseArrow {
//    transform: rotate(-180deg);
//    margin-top: -1px;
//    svg {
//      path {
//        fill: #f76552;
//      }
//    }
//  }
//}
// -------- END data clients info
.mrr-chart-legend-popover {
  max-width: 345px;
  padding: 4px 4px;

  p,
  .mclp-text {
    font-size: 12px;
  }

  .mclp-con {
    margin-right: 10px;
  }
}
// CUSTOM tooltip for MRR chart
#mrr-custom-tooltip {
  width: 330px;
  background: #ffffff;
  border: 1px solid rgba(114, 114, 114, 0.14);
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  padding: 20px;
  filter: drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.08));
  &:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    bottom: -10px;
    left: 90px;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #ffffff transparent;
    border-style: solid;
    transform: rotate(180deg);
  }
}
.mrr-custom-tooltip__inner {
  width: 100%;
  font-family: inherit;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0d1333;

  .title {
    font-family: inherit;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    color: #f76552;
    padding-bottom: 12px;
  }

  table {
    width: 100%;
  }

  .line-item {
    display: flex;
    // grid-template-columns: 0.5fr 0.5fr;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    font-size: 12px;
    &:last-child {
      margin-bottom: 0;
    }
    .left {
      span {
        white-space: nowrap;
      }
    }
    .right {
      display: flex;
      justify-content: flex-start !important;
      align-items: center;

      .nowrap {
        white-space: nowrap;
      }
    }
    .time {
      margin-left: 30px;
      white-space: nowrap;
    }
  }
}

//ai widget styles
.ai-widget-wrap {
  overflow: auto;
  height: calc(100% - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .ai-widget-body {
    div {
      h1,
      h2,
      h3 {
        display: none;
      }
      height: 100%;
      // overflow: auto;
    }

    ::-webkit-scrollbar {
      cursor: pointer !important;
      width: 12px;
      margin-top: 15px;
      height: 10px;
    }
  }
}
.ai-date {
  margin-top: 10px;
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
}
/*------------- END Data cart styles -------------*/
