.search-wrap {
  display: flex;
  justify-content: center;
  align-items: center;

  .search-account-input {
    border: 1px solid var(--border-color);
    border-right: none;
    border-radius: 6px 0 0 6px;
    padding: 10px;
    height: 44px;
  }
  .search-btn {
    padding: 13px 10px 13px 0px;
    border: 1px solid var(--border-color);
    border-left: none;
    border-radius: 0 6px 6px 0;
    background: transparent;
  }

  .search-input {
    border: 1px solid #e0eceb;
    border-right: none;
    border-radius: 6px 0 0 6px;
    padding: 5px;
  }
}

.user-invitations {
  .search-wrap {
    display: flex;
    justify-content: center;
    align-items: center;

    .search-btn {
      padding: 14px 10px 13px 0px;
      border: 1px solid #e0eceb;
      border-left: none;
      border-radius: 0 6px 6px 0;
      background: transparent;
    }

    .search-input {
      border: 1px solid #e0eceb;
      border-right: none;
      border-radius: 6px 0 0 6px;
      padding: 5px;
    }
  }
}

// create new board
.api-acc-search {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--border-color);

  .search-account-input {
    width: 100%;

    &:focus {
      border: 1px solid var(--primary-n);
      border-right: none;
    }
  }

  .search-btn {
    padding: 10px 10px 10px 0;
  }

  .search-account-input:focus ~ .search-btn {
    border: 1px solid var(--primary-n) !important;
    border-left: none !important;
  }
}

.chart-of-acc-search {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--border-color);

  .search-account-input {
    width: 100%;

    &:focus {
      border: 1px solid var(--primary-n);
      border-right: none;
    }
  }

  .search-btn {
    padding: 10px 10px 10px 0;
  }

  .search-account-input:focus ~ .search-btn {
    border: 1px solid var(--primary-n) !important;
    border-left: none !important;
  }
}
