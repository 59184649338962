// .progress-bar {
//   position: fixed;
//   bottom: 50px;
//   right: 50px;
//   height: 30px;
//   width: 250px;
//   border-radius: 80px;
//   z-index: 2;
//   box-shadow: 0px 4px 12px rgba(239, 80, 95, 0.3);
//   font-style: normal;
//   font-weight: 600;
//   font-size: 16px;
//   line-height: 28px;
//   text-align: center;
//   color: #ffffff;
//   letter-spacing: 1px;
//   background-color: white;

//   .p-filler {
//     background: linear-gradient(270deg, #f76552, #dd2476, #ce005b, #ff1d00);
//     background-size: 800% 800%;

//     -webkit-animation: AnimationName 4s ease infinite;
//     -moz-animation: AnimationName 4s ease infinite;
//     animation: AnimationName 4s ease infinite;

//     height: 105%;
//     border-radius: inherit;
//     transition: width 0.2s ease-in;
//   }
// }

.infinite-loader{
  position: fixed;
  bottom: 20px;
  right: 50px;

  .cool-loading{
    position: relative;
  }
}
