@import '../base/mixins';

.page-dashboard,
.page-superadmin {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;

    width: 80px;
    height: 100vh;
    min-height: 500px;
    padding: 10px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    justify-items: center;
    align-items: flex-start;
    flex-wrap: wrap;
    // border: 1px solid var(--border-color);

    background: #ffffff;
    box-shadow: 0px -10px 30px rgba(188, 196, 208, 0.04);

    -webkit-transition: width 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;
    z-index: 99;

    .brand-sidebar {
      position: relative;
      padding-top: 12px;
      padding-left: 4px;
      height: 67px;

      .close-sidebar {
        position: absolute;
        top: 18px;
        left: 48px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        box-shadow: 0px -10px 30px rgba(188, 196, 208, 0.04);
        border-right: 1px solid #e8e8e8;
        cursor: pointer;
        transition: left 0.5s ease-in-out, transform 0.3s ease-in;
      }
      .reportify-name {
        opacity: 0;
      }
    }
    .nav-menus {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        transition: width 0.5s ease-in-out;
        &:hover {
          border-color: var(--primary-n) !important;
        }
      }
      .icon--active {
        border-color: var(--primary-n) !important;
      }
    }
  }

  .side-bar-navs {
    position: relative;
    text-decoration: none;
    padding: 10px 14px 10px 14px;
    background-color: transparent;
    border: none;
    outline: none;
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 2px solid transparent;
    color: #282828;

    &:hover {
      background-color: rgba(247, 101, 82, 0.06);
      border-left: 2px solid #f76552;
      color: #f76552;

      .folder-icon {
        svg path {
          fill: #f76552;
        }
      }
    }
    .action-icon-wrap {
      svg {
        margin-left: 4px;
      }
      svg path {
        stroke: #f76552;
      }
    }

    .nav-text {
      overflow: hidden;
      white-space: nowrap;
      max-width: 0px;
      line-height: 22px;
      transition: all 0.3s ease-in-out;
    }

    .no-api-circle {
      width: 9px;
      max-width: 9px;
      max-height: 9px;
      height: 9px;
      background: #ffa79b;
      border: 3px solid #f64831;
      border-radius: 30px;
      position: absolute;
      top: 3px;
      right: 4px;
      animation: 1.3s blink ease-in-out infinite;
    }

    @keyframes blink {
      from,
      to {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
    }

    .mr-2 {
      margin-right: 0 !important;
    }
  }

  .expanded-sidebar {
    width: 245px;

    .brand-sidebar {
      display: flex;
      // align-items: ;

      .close-sidebar {
        left: 217px;
        transform: rotate(180deg);
        transition: left 0.5s ease-in-out, transform 0.3s ease-in;
        border-left: 1px solid #e8e8e8;
        border-right: none;
      }

      .reportify-name {
        opacity: 1;
        transition: opacity 1s ease-in-out;
        font-size: 20px;
        margin-left: 5px;
        margin-top: 4px;
      }
    }

    .nav-menus {
      padding-left: 20px;
      padding-top: 8px;
      display: block;
      .icon {
        width: 190px;
        height: 42px;
        padding-left: 10px;
        justify-content: flex-start;
        color: #2d2d2d;
        transition: width 0.5s ease-in-out;
        text-decoration: none;
        svg {
          margin-right: 10px;
        }

        &:hover {
          color: var(--primary-n);
          border-color: var(--primary-n) !important;
        }
      }
      .icon--active {
        color: var(--primary-n);
        border-color: var(--primary-n) !important;
      }

      .nav-text {
        opacity: 1;
        max-width: 200px;
        width: auto;
        transition: opacity 2s ease-in-out;
      }
      .mr-2 {
        margin-right: 0.5rem !important;
      }
    }
  }

  .main-menu {
    display: flex;
    margin-top: 7px;
    flex: 2 1 0;
    flex-direction: column;
    overflow-x: hidden;

    .icon {
      margin: 15px 0;
    }
  }

  .side-bar-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: space-between;

    .side-bar-top-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: space-between;
    }

    .side-bar-bottom-section {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      align-content: space-between;
    }
  }
}

.sidebar-profile-dropdown {
  left: 105% !important;
}

.dropdown-sub-board {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0px 0px;
  gap: 4px;
  position: absolute;
  width: 200px;
  max-height: 500px;
  background: #ffffff;
  border: 1px solid rgba(114, 114, 114, 0.14);
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
}
