//chart widget 5
.top-5-chart {
  canvas {
    max-width: 450px;
    max-height: 260px;
    margin: auto;
  }
}
.cw-legend {
  padding: 20px 0px;
  width: 100%;

  .legend-button-wrap {
    text-align: left;
    padding-bottom: 16px;

    .expense-value {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #2d2d2d80;
      margin-left: 30px;
    }
  }
  .card-filter-btn-wrap {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .card-filter-btn__label {
      // overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.number-switch {
  .ant-switch-checked {
    background-color: var(--primary-n);
  }
  button {
    margin: 0 5px;
  }
}

// top 10 chart widgets
.top-10-chart {
  height: 100%;
  canvas {
    max-width: 850px;
    max-height: 450px;
    margin: 0;
  }

  .col-7,
  .col-5 {
    // height: 100%;
    display: flex;
    align-items: center;
  }
}

//empty widget styles
.empty-widget-data {
  display: flex;
  font-size: 16px;
  flex-direction: column;
  width: 100%;
  height: 250px;
  justify-content: center;
  align-items: center;
}
