.ps-layout-wrap {
  padding: 0 30px;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: #fafafa;

  .ps-layout {
    width: 950px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;

    .ps-layout-header {
      width: 100%;
      background: #ffffff;
      border: 1px solid rgba(188, 196, 208, 0.2);
      box-shadow: 0px 8px 24px rgba(188, 196, 208, 0.04);
      border-radius: 10px;
      padding: 21px;
      margin-bottom: 55px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h5 {
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 0;
      }

      //api-settings-nav
      .api-settings-nav {
        display: flex;
        .api-settings-nav-item {
          border: 1px solid rgba(45, 45, 45, 0.1);
          box-sizing: border-box;
          border-radius: 8px;
          margin-left: 20px;
          display: flex;
          align-items: center;
          padding: 10px;
          cursor: pointer;
          color: #2d2d2d;
          transition: all 0.3s ease;
          svg {
            margin-right: 5px;
          }

          &:hover {
            background: rgba(247, 101, 82, 0.06);
            border-color: var(--primary-n);
            color: var(--primary-n);
            svg {
              path {
                // stroke: var(--primary-n);
                // fill: var(--primary-n);
              }
            }
          }
        }
        .api-settings-nav-item--active {
          background: rgba(247, 101, 82, 0.06);
          border-color: var(--primary-n);
          color: var(--primary-n);
          svg {
            path {
              // stroke: var(--primary-n);
              // fill: var(--primary-n);
            }
          }
        }
        a {
          text-decoration: none;
        }
      }
    }

    .ps-layout-body {
      width: 100%;
      display: flex;

      .pslb-nav {
        width: 236px;
        min-width: 236px;
        height: 128px;
        background: #ffffff;
        border: 1px solid rgba(188, 196, 208, 0.2);
        box-shadow: 0px 8px 24px rgba(188, 196, 208, 0.04);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        padding: 22px;
        padding-left: 0;
        margin-right: 20px;

        .pslb-nav-item {
          text-decoration: none;
          color: #2d2d2d;
          border-left: 3px solid transparent;
          margin-bottom: 22px;
          display: flex;
          align-items: center;

          svg {
            margin: 0 15px;
          }

          &:hover {
            transition: all 0.5s ease-in-out;
            border-left: 3px solid var(--primary-n);
            svg {
              path {
                stroke: var(--primary-n);
              }
            }
            color: var(--primary-n);
          }
        }

        .pslb-nav-item--active {
          border-left: 3px solid var(--primary-n);
          svg {
            path {
              stroke: var(--primary-n);
            }
          }
          color: var(--primary-n);
        }
      }
      .pslb-content {
        padding: 30px;
        width: 700px;
        background: #ffffff;
        border: 1px solid rgba(188, 196, 208, 0.2);
        box-shadow: 0px 8px 24px rgba(188, 196, 208, 0.04);
        border-radius: 10px;
      }

      .pslb-content,
      .api-settings-content {
        .form-control {
          border: 1px solid #fee5e2 !important;

          &:hover,
          &:focus,
          &:active {
            border-color: #fa978a !important;
          }

          height: 44px;
        }

        button {
          height: 44px;
        }

        .ant-select {
          height: 44px;
          border: 1px solid var(--border-color);
          border-radius: 6px;

          .ant-select-selector {
            height: 42px;
            padding: 0 5px;

            .ant-select-selection-item {
              height: 36px;
            }
          }
        }

        .user-invitations {
          .ant-select {
            border: none;
          }
        }

        .single-date-picker {
          border: 1px solid var(--border-color) !important;
          padding: 12px;
          border-radius: 6px;

          &:hover,
          &:focus {
            border-color: #fa978a !important;
          }
        }

        .search-btn {
          height: 44px;
          padding-top: 9px;
        }
      }

      .api-settings-content {
        .card {
          padding: 20px;
          border: 1px solid rgba(188, 196, 208, 0.2);
          box-sizing: border-box;
          box-shadow: 0px 8px 24px rgba(188, 196, 208, 0.04);
          border-radius: 10px;

          .board-api-acc-dd {
            height: 400px;
          }
        }
      }
    }
  }

  .personal-info-save-btn {
    margin-top: 60px;
  }

  //password validation box
  .pvb-wrap {
    width: 200px;
    background: #ffffff;
    border: 1px solid rgba(188, 196, 208, 0.4);
    box-shadow: 0px 8px 24px rgba(188, 196, 208, 0.2);
    border-radius: 10px;
    top: 30px;
    left: -225px;
    font-size: 12px;
    padding: 8px 0;

    .pvb-content {
      .pvb-item {
        display: flex;
        align-items: center;
        padding: 5px 10px;

        img {
          margin-right: 5px;
        }
      }
    }

    .triangle {
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid rgba(188, 196, 208, 0.4);
      position: absolute;
      right: -11px;
      top: 15px;
    }
  }

  @media (max-width: 1200px) {
    .ps-layout {
      width: 100%;
    }
  }

  @media (max-width: 992px) {
    .ps-layout-body {
      flex-direction: column;
      .pslb-nav {
        width: 100% !important;
        margin-right: 0;
        margin-bottom: 22px;
      }

      .pslb-content {
        margin-top: 60px;
        width: 100% !important;
      }
    }
  }
}
