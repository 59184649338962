.auto-height-list {
  .ant-select-selector {
    height: auto !important;
    min-width: 60px;
    max-height: 50px !important;
    overflow: auto;
    overflow-x: hidden;
  }
}
.consolidation-ms-option-wrap {
  .consolidation-ms-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
  }
}

.consolidation-ms-option-wrap.ant-select-item-option-selected {
  // background-color: black !important;
  svg path {
    stroke: var(--primary-n);
  }
}

.debounce-select {
  .ant-select {
    height: 50px !important;
    .ant-select-selection-placeholder {
      top: 66%;
    }

    .ant-select-selector {
      height: 49px !important;
      .ant-select-selection-placeholder {
        padding-bottom: 10px;
      }
    }
    &:hover {
      border: 1px solid #f76552 !important;
    }
  }
  .ant-select:focus {
    border: 1px solid #f76552 !important;
  }
  .ant-select-selection-overflow-item {
    .ant-select-selection-item-content {
      white-space: nowrap;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 6px;

      &:hover {
        svg path {
          stroke: var(--primary) !important;
        }
      }
    }

    .ant-select-selection-item-remove {
      margin: 4px;
      &:hover {
        svg path {
          stroke: var(--primary) !important;
        }
      }
    }

    &:hover {
      svg path {
        stroke: var(--primary) !important;
      }
    }
  }

  &:hover {
    svg path {
      stroke: var(--primary) !important;
    }
  }

  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
}

.consolidation-dd-list {
  white-space: nowrap;
  font-weight: 500px;
  font-size: 14px;
  line-height: 30.4px;
}

.debounce-select-details {
  width: 520;
  height: auto;
}

.consolidate-content {
  max-height: 725px;
  margin-top: 8px;

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--primary-n);
    border-radius: 1px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #f64831;
  }
}

.consolidation-heading {
  // padding: 6px 6px !important;
  // background: #ffffff;
  // border: 1px solid $light-border !important;
  // box-sizing: border-box;
  border-radius: 20px;
  // margin-bottom: 0px;
  align-items: center;
  height: 35px;

  &:last-child {
    margin-bottom: 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  &__edit,
  &__delete {
    cursor: pointer;

    svg path {
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      svg path {
        stroke: #f76552 !important;
        fill: #f76552 !important;
      }
    }
  }
}
.card--account__save {
  cursor: pointer;
  &:hover {
    svg path {
      fill: #f76552 !important;
    }
  }
}

.ss-auto-height-list-options {
  background: linear-gradient(0deg, #fff6f5, #fff6f5), linear-gradient(217.94deg, #f76552 1.68%, #dd2476 237.76%);
  /* Current website/3 */

  border: 1px solid #f76552;
  border-radius: 5px;
}

.add-new-consolidation-group {
  display: flex;
  align-items: center;
  justify-content: center;

  .btn-outline-light {
    svg path {
      stroke: #f76552 !important;
      opacity: 0.9;
    }

    &:hover {
      svg path {
        fill: #f76552 !important;
        stroke: #f8f9fa !important;
      }
    }
  }
}

.consolidation-group-section {
  display: flex;
  align-items: center;
  justify-content: space-between;

  // .group-name {
  // align-items: center;
  // justify-content: flex-start;
  // }

  // .group-ops {
  // align-items: center;
  // justify-content: flex-start;
  // flex-direction: column;
  // }

  // flex-direction: column;
  // justify-content: space-between;
  // justify-items: center;
  // align-content: flex-end;
  // align-items: flex-end;
  // flex-wrap: wrap;
}

.group-ops-list {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .anticon svg {
    height: 16px;
    width: 16px;
  }
}

.consolidation-item-wrap {
  // border-left: 1px solid #fee5e2;
  margin-left: 10px;
  display: flex;
  align-items: center;
  position: relative;

  .consolidation-border-left {
    position: absolute;
    top: 0;
    height: calc(100% - 25px);
    border-left: 2px solid #fee5e2;
  }

  .consolidation-apis-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0 0 0px;

    .api-account-list {
      display: flex;
      align-items: center;
      .btn-border {
        width: 20px;
        height: 2px;
        display: block;
        background: #fee5e2;
      }
      .btn-outline {
        background: rgba(247, 101, 82, 0.04);
        border: 1px solid #fee5e2;
        border-radius: 6px;
        height: 50px !important;
      }
      &:hover {
        .btn-outline {
          border: 1px solid rgba(247, 101, 82, 0.6);
        }
      }
    }
  }
}

.consolidation-apis-title-section {
  display: flex;
  padding-top: 18px;
  padding-left: 21px;
  align-items: center;
  justify-content: left;
}

.collapse-icon {
  height: 18px !important;
  width: 16px !important;
  opacity: 0.7;

  &:hover {
    svg {
      fill: var(--primary) !important;
    }
  }
}
