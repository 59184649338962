@import '../base/mixins';

.app-controls {
  display: flex;
  align-items: center;
  align-content: center;

  @include rwd(1155) {
    margin-top: 0;
  }

  @include rwd(977) {
    margin-bottom: 0;
  }

  .btns {
    margin-right: 6px;
    width: 44px;
    height: 44px;
    border-radius: 5px;
    border: 1px solid transparent;
    position: relative;
    margin-left: 11px;

    svg path {
      transition: all 0.3s ease-in-out;
    }

    svg rect {
      transition: all 0.3s ease-in-out;
    }

    .hover-text {
      position: absolute;
      min-width: 150px;
      left: 50%;
      bottom: -29px;
      font-size: 12px;
      letter-spacing: 1px;
      color: #0d1333;
      transform: translateX(-50%);
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      background-color: var(--light-bg);
      border-color: var(--primary-n);

      .hover-text {
        bottom: -25px;
        opacity: 1;
        visibility: visible;
      }

      svg path {
        stroke: var(--primary-n) !important;
        fill: var(--primary-n) !important;
      }

      // svg

      svg rect {
        //stroke: var(--primary-n) !important;
        fill: var(--primary-n) !important;
      }

      // svg
    }

    // btn hover
  }
}
