:root {
  --text: #2d2d2d;
  --primary: #dd2476;
  --primary-n: #f76552;
  --light-bg: rgba(247, 101, 82, 0.1);
  --light-bg-solid: #e0eceb;
  --light-text: #999fbf;
  --theme-blue: #5b76f9;
  --theme-lightblue: #35c3fc;
  --theme-green: #33d69f;
  --theme-lightgreen: #33d067;
  --theme-teal: #21837b;
  --theme-lightteal: #2b9a9c;
  --light: #999fbf;
  --theme-red: #f76552;
  --border-color: #fee5e2;
  --border-light: #feeeec;
}

.dim-text {
  color: #383f4581;
  font-weight: 400;
}

.disable-feature {
  opacity: 0.3;
  pointer-events: none;
}
.disable-feature-2 {
  opacity: 0.6;
  pointer-events: none;
}

a,
button,
.btn,
.btn-link,
input,
select,
.form-control,
.custom-select {
  box-shadow: none;
  outline: none;

  &:focus,
  &:active,
  &:visited {
    box-shadow: none !important;
    outline: none !important;
    border-color: var(--light-bg);
  }
}

.dropdown-menu {
  border-color: var(--light-bg);
}

.dropdown-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 28px !important;

  &::after {
    border: none !important;
    height: 20px;
    width: 20px;

    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0iIzJEMkQyRCIgZD0iTTE3LDkuMTdhMSwxLDAsMCwwLTEuNDEsMEwxMiwxMi43MSw4LjQ2LDkuMTdhMSwxLDAsMCwwLTEuNDEsMCwxLDEsMCwwLDAsMCwxLjQybDQuMjQsNC4yNGExLDEsMCwwLDAsMS40MiwwTDE3LDEwLjU5QTEsMSwwLDAsMCwxNyw5LjE3WiIvPjwvc3ZnPg==');
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}

.custom-select {
  //dropdown dark
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0iIzJEMkQyRCIgZD0iTTE3LDkuMTdhMSwxLDAsMCwwLTEuNDEsMEwxMiwxMi43MSw4LjQ2LDkuMTdhMSwxLDAsMCwwLTEuNDEsMCwxLDEsMCwwLDAsMCwxLjQybDQuMjQsNC4yNGExLDEsMCwwLDAsMS40MiwwTDE3LDEwLjU5QTEsMSwwLDAsMCwxNyw5LjE3WiIvPjwvc3ZnPg==');
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 99% center;
  border: 1px solid rgba(#2d2d2d, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  width: 300px;
  cursor: pointer;
  height: 47px;
  // color: #fff;
  font-weight: bold;
}

.custom-select:hover {
  background-color: var(--light-bg);
}

/*------- datepicker html --------*/
input[type='date'] {
  appearance: none;
  //box-sizing: border-box;
  //border: 1px solid black;
  background: #fff;
  //font-size: 1.5rem;
  //padding: 8px;
  &::-webkit-datetime-edit-text {
    padding: 0 5px;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator,
  &::-webkit-outer-spin-button {
    appearance: none;
    -webkit-appearance: none;
    display: none;
  }
}

/*------- END datepicker html --------*/

/*------- START tables --------*/
.table {
  margin-bottom: 0;
}

.thead-primary {
  background-color: #fff;
}

.tfoot-primary {
  background-color: #fff;
}

.table td,
.table th {
  vertical-align: middle;
  border-top: 1px solid #e8e8e8;
}

/*------- END tables --------*/

/*------- START Text colors updates --------*/
.color-light {
  color: var(--light-text) !important;
}

.color-green {
  color: var(--theme-lightgreen) !important;
}

.color-red {
  color: var(--theme-red) !important;
}

.text-primary {
  color: var(--primary-n) !important;
}

/*------- END Text colors updates --------*/
/*------- START card updates --------*/
.card {
  border: 0;
  border-radius: 10px;
}

/* ------ START button style updates ------- */
.btn-primary-new {
  background: var(--primary-n);
  box-shadow: 0px 6px 20px rgba(43, 154, 156, 0);
  border-radius: 5px;
  padding: 6px 14px;
  border-color: var(--primary-n);
  transition: box-shadow 0.3s ease-in-out;
  height: 44px;
  border: none;
  color: #fff;

  &:hover {
    background: var(--primary-n);
    border-color: var(--primary-n);
    box-shadow: 0px 6px 20px rgba(43, 154, 156, 0.4);
  }
  &:active {
    background-color: var(--primary-n) !important;
    border-color: var(--primary-n) !important;
  }
}

.btn-outline-primary {
  color: var(--primary-n);
  background: #ffffff;
  border: 1px solid #c3dad8;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0px 6px 20px rgba(43, 154, 156, 0);
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    background: var(--primary-n);
    border-color: var(--primary-n);
    box-shadow: 0px 6px 20px rgba(43, 154, 156, 0.4);
  }
  &:active {
    background-color: var(--primary-n) !important;
    border-color: var(--primary-n) !important;
  }
}

.btn-outline-light {
  color: var(--primary-n);
  background: linear-gradient(0deg, #fff6f5, #fff6f5), linear-gradient(217.94deg, #f76552 1.68%, #dd2476 237.76%);
  border: 1px solid var(--primary-n);
  box-sizing: border-box;
  line-height: 27px;
  padding: 6px 12px;
  border-radius: 5px;
  box-shadow: 0px 6px 20px rgba(43, 154, 156, 0);
  transition: box-shadow 0.3s ease-in-out;
  height: 44px;

  &:hover {
    background: var(--primary-n);
    border-color: var(--primary-n);
    box-shadow: 0px 6px 20px rgba(43, 154, 156, 0.4);
    color: #fff !important;

    path {
      stroke: #fff;
      fill: #fff;
    }
  }
  &:active {
    background-color: var(--primary-n) !important;
    border-color: var(--primary-n) !important;
  }

  &:disabled {
    color: #f76552;
  }
}

.btn-secondary {
  color: #2d2d2d;
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  line-height: 27px;
  border-radius: 5px;
  padding: 6px 12px;
  box-shadow: 0px 6px 20px rgba(43, 154, 156, 0);
  transition: box-shadow 0.3s ease-in-out;
  height: 44px;

  &:hover {
    border-color: #2d2d2d;
    box-shadow: 0px 6px 20px rgba(43, 154, 156, 0.4);
  }
  &:active {
    background-color: var(--primary-n) !important;
    border-color: var(--primary-n) !important;
  }
}

.btn-secondary-outline {
  color: #2d2d2d;
  background: #fff;
  border: 1px solid rgba(114, 114, 114, 0.14);
  box-sizing: border-box;
  line-height: 27px;
  border-radius: 5px;
  padding: 6px 12px;
  box-shadow: 0px 6px 20px rgba(43, 154, 156, 0);
  transition: box-shadow 0.3s ease-in-out;
  height: 44px;

  &:hover {
    border-color: var(--primary-n);
    color: var(--primary-n);
    background: linear-gradient(0deg, #fff6f5, #fff6f5), linear-gradient(217.94deg, #f76552 1.68%, #dd2476 237.76%);
  }
  // &:active {
  //   background-color: #f2f2f2 !important;
  //   color: rgba(114, 114, 114, 0.14) !important;
  // }
}

.btn--download-invoice {
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;

  img {
    width: 22px;
    height: 22px;
  }

  &:hover {
    opacity: 1;
  }
}

/* ------ END button style updates ------- */

/* ------ Custom Notification -------- */
.ant-notification-notice-with-icon {
  span {
    margin-top: -3px;
  }
  .ant-notification-notice-message {
    margin-top: 5px;
  }
}
/* ------ Custom Notification Ends -------- */

/* ------ START error page ------- */
.error-screen {
  display: flex;
  height: 100vh;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 2.5rem;
    font-weight: 500;
    color: var(--theme-red);
  }
}
/* ------ END error page ------- */

/* ------ START reset btn ------- */
.reset-btn,
button.ant-btn.ant-btn-default.reset-btn {
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  line-height: 1;
  height: auto;
  min-height: 1px;
}
/* ------ END reset btn ------- */

button:disabled {
  pointer-events: none;
  opacity: 0.7;
}
