@import url('https://fonts.googleapis.com/css2?family=Readex+Pro&display=swap');

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('../../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('../../fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('../../fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('../../fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
}

:root {
  --font-family-sans-serif: 'Poppins' !important;
}

body,
html {
  font-family: 'Poppins', 'serif' !important;
}
