.data-table {
  padding: 0 30px;
}

.widget-page-header {
  max-width: 100%;
  width: 100%;
  padding: 0 30px;
}

.data-table__header {
  align-items: center;
  margin-bottom: 30px;

  .card-title {
    margin-bottom: 0;
  }
}

.data-table__cashflow-table {
  overflow-x: auto;
  border-radius: 10px;

  th.text-right {
    width: 170px;
  }

  tbody {
    tr {
      td {
        text-align: right;
        min-width: 170px;
        border: 1px solid #e8e8e8;
      }
      td:first-child {
        max-width: 400px;
        min-width: 340px;
        text-align: left;
        // border: none;
      }
    }

    tr.parent {
      cursor: pointer;

      .table-line-title {
        display: flex;
        align-items: flex-start;

        svg {
          min-width: 24px;
        }
      }

      .child-text {
        color: #777;
        font-weight: 300;
      }

      td:first-child {
        font-weight: bold;
      }
    }

    tr.child {
      display: none;
      color: #777;
      font-weight: 300;

      td:first-child {
        padding-left: 60px;
        padding-right: 40px;
      }
      .table-expand-icon {
        display: none;
      }
    }

    @for $i from 0 through 30 {
      &.parent-#{$i} {
        tr.child-#{$i} {
          display: table-row;
        }
      }
    }
    @for $i from 0 through 30 {
      &.bl-table {
        tr.child-#{$i} {
          display: table-row;
        }
      }
    }
  }

  @for $i from 0 through 50 {
    .cf-table-body.parent-#{$i} {
      .parent-#{$i} {
        .table-expand-icon {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          transition-duration: 0.2s;
          transition-property: transform;
          transition-timing-function: ease-out;
        }
      }
      // td:first-child {
      //   border: 1px solid #e8e8e8;
      // }
    }
  }
}

.data-table__profitloss-table {
  overflow: auto hidden;
  border-radius: 10px;

  th.text-right {
    width: 170px;
  }
  tr {
    th:first-child {
      width: 120px;
      max-width: 120px;
    }
  }

  tbody {
    tr {
      td {
        text-align: right;
        min-width: 170px;
        border: 1px solid #e8e8e8;
      }
      td:first-child {
        text-align: left;
        max-width: 220px;
        min-width: 200px;
        border: none;
      }
      td:nth-child(2) {
        max-width: 120px;
        min-width: 200px;
        text-align: left;
        border: none;
        //width: 200px;
      }
    }

    tr.parent {
      cursor: pointer;

      .table-line-title {
        display: flex;
      }

      td:first-child {
        font-weight: bold;
      }
    }

    tr.child {
      display: none;
      color: #777;
      font-weight: 300;

      // td {
      //   padding-left: 45px;
      // }

      td:first-child {
        padding-left: 60px;
        padding-right: 20px;
        width: 120px;
        max-width: 120px;
      }
      .table-expand-icon {
        display: none;
      }
    }

    @for $i from 0 through 50 {
      &.parent-#{$i} {
        tr.child-#{$i} {
          display: table-row;
        }
      }
    }
  }
  @for $i from 0 through 50 {
    .cf-table-body.parent-#{$i} {
      .parent-#{$i} {
        .table-expand-icon {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          transition-duration: 0.2s;
          transition-property: transform;
          transition-timing-function: ease-out;
        }
      }
      td:first-child {
        border: 1px solid #e8e8e8;
      }
      td:nth-child(2) {
        border: 1px solid #e8e8e8;
      }
    }
  }
}

.tfoot-primary {
  tr {
    td {
      text-align: right;
      min-width: 170px;
    }
    td:first-child {
      text-align: left;
      padding-left: 60px;
      width: 220px;
    }
  }
}
.t-footer-body {
  border-top: none !important;
  tr {
    td {
      text-align: center;
      min-width: 170px;
    }
    td:first-child {
      text-align: left;
      padding-left: 60px;
      width: 220px;
    }
  }
}

//balance sheet table
.cf-table-body {
  tr.balance-sheet {
    .balance-sheet-icon {
      transition-duration: 0.2s;
      transition-property: transform;
      transition-timing-function: ease-out;
    }
    .table-line-title {
      justify-content: flex-end;
    }
    td:first-child {
      display: flex;
    }
  }
}

table {
  box-shadow: 0 5px 12px 0px rgba(0, 0, 0, 0.06), 0 5px 6px -2px rgba(0, 0, 0, 0.06);
  width: 1400px;
  min-width: 100%;

  tbody {
    thead {
      tr {
        th {
          border: 1px solid #e8e8e8;
        }
      }
    }
  }
}

.sticky-col {
  position: sticky !important;
  left: 0;
  background-color: white;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.1));
}
.sticky-col-cf {
  position: sticky !important;
  left: 0;
  background-color: white;
  z-index: 2 !important;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.1));
}

.sticky-col2 {
  position: sticky !important;
  left: 200px;
  background-color: white;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.1));
}

.sticky-col-th {
  position: sticky !important;
  left: 0;
  border-right: 1px solid transparent !important;
  background-color: white;
  filter: drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.1)) !important;
  z-index: 2;
}

.sticky-col-cfth {
  position: sticky !important;
  left: 0;
  border-right: 1px solid transparent !important;
  background-color: white;
  filter: drop-shadow(1px 0px 0px rgba(0, 0, 0, 0.1)) !important;
  z-index: 2;
}

.sticky-col-th2 {
  position: sticky !important;
  left: 200px;
  background-color: white;
  z-index: 2;
}

.sticky-col-tf {
  position: sticky !important;
  left: 0;
  border-right: 1px solid transparent !important;
  background-color: white;
  z-index: 2;
}

.sticky-col-cftf {
  position: sticky !important;
  left: 0;
  filter: drop-shadow(1px 0px 0px rgba(0, 0, 0, 0.1)) !important;
  background-color: white;
  z-index: 2;
}

.sticky-col-tf2 {
  position: sticky !important;
  left: 200px;
  background-color: white;
  z-index: 2;
  filter: drop-shadow(1px 0px 0px rgba(0, 0, 0, 0.1)) !important;
}
