.custom-widget-modal {
  width: 466px !important;
  .ant-modal-close-icon {
    border-radius: 50%;
    background-color: lightgrey;
    padding: 5px;
    vertical-align: 2px !important;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    border-radius: 10px 10px 0 0;
  }

  .cnbm-footer {
    display: flex;
    justify-content: space-between;
  }

  .form-control:focus {
    border: 1px solid #f76552 !important;
  }
}

//custom radio button
.widget-type-radio {
  display: flex;
  justify-content: space-between;
  position: relative;

  .custom-widget-type-wrap {
    display: flex;
    align-items: center;

    .radio-container {
      display: flex;
      align-items: center;
      cursor: pointer;

      .not-selected {
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
        border: 2px solid #e53c3c3b;
        border-radius: 50%;
        transition: all 0.3s ease-in-out;
      }

      .selected {
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
        border: 4px solid #ffffffa8;
        border-radius: 50%;
        background: #f76552;
        transition: all 0.3s ease-in-out;
      }

      span {
        margin-left: 8px;
      }

      .info-icon {
        margin-left: 8px;
        cursor: pointer;
        margin-top: -3px;
        // position: relative;

        .info-text {
          position: absolute;
          top: 30px;
          left: 50%;
          transform: translateX(-50%);
          background-color: #2d2d2d;
          color: #fff;
          padding: 6px 10px;
          border-radius: 7px;
          white-space: nowrap;
          font-size: 12px;
          max-width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:hover {
          svg {
            fill: #000;
            path {
              stroke: #fff;
            }
          }
        }
      }
    }
  }
}

.custom-widget-modal-wrap {
  .form-label {
    text-transform: uppercase;

    span {
      color: var(--primary-n);
    }
  }

  .ant-select-selector,
  .form-control {
    border: 1px solid var(--border-color) !important;
    height: 50px !important;
    border-radius: 6px !important;
    box-shadow: none !important;
  }

  .ant-select-selection-item {
    height: auto;
    margin: 7px 0;
    display: flex;
    align-items: center;
    color: #000;
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }

  .no-text {
    color: transparent;
  }

  .cw-image {
    display: flex;
    justify-content: center;
    padding-top: 8px;

    img {
      width: 335px;
      height: 220px;
    }
  }
}

//Circle widget
.circle-widget {
  .edit-widget {
    cursor: pointer;
    margin-left: 15px;
    svg {
      width: 15px;
    }
  }

  .number-switch {
    margin-top: 2px;
  }

  .cw-body {
    .cw-body-wrap {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .cw-value {
        margin: 9px 0 15px 0;
        width: 170px;
        height: 170px;
        border-radius: 200px;
        background-color: #fef0ee;
        border: 10px solid rgba(247, 101, 82, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 55px;
        // line-height: 42px;
        color: #f76552;
      }

      .cw-note1 {
        margin-bottom: 5px;
      }

      .cw-with-note {
        margin-top: 25px;
      }
    }
  }
  .turn-over-widget {
    margin-top: 40px;
    .cw-body-wrap {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      align-items: center;

      .to-widget-titles {
        text-align: left;
        font-weight: 400;
        font-size: 16px;
        line-height: 34px;

        span {
          color: rgba(91, 118, 249, 1);
        }
      }
      .cw-value {
        border-color: rgba(91, 118, 249, 0.1);
        color: rgba(91, 118, 249, 1);
        background-color: #edf0ff;
      }
    }
  }

  .cw-note1,
  .cw-note2,
  .cw-with-note,
  .nw-note,
  .nw-note2 {
    font-size: 14px;
    font-weight: 500;
  }
}

//number widget
.number-widget {
  .nw-body-wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    .nw-value {
      margin: 45px 0 15px 0;
      background: rgba(247, 101, 82, 0.06);
      border: 2px solid rgba(247, 101, 82, 0.1);
      border-radius: 20px;
      font-weight: 700;
      font-size: 50px;
      line-height: 42px;
      color: #f64831;
      padding: 21px 30px;
    }

    .mrr-value {
      margin: 35px 0 15px 0;
      background: #edf0ff;
      border: 4px solid rgba(91, 118, 249, 0.1);
      border-radius: 12px;
      font-weight: 700;
      font-size: 50px;
      min-width: 250px;
      line-height: 42px;
      color: #5b76f9;
      padding: 30px;

      @media (min-width: 1800px) {
        width: 340px;
      }

      .mrr-month {
        font-weight: 400;
        font-size: 14px;
        color: #2d2d2d;
        display: flex;
        align-items: center;
        justify-content: center;

        .mrr-month-bold {
          font-size: 14px;
          font-weight: 700;
        }

        .percentage {
          margin-top: 2px;
          margin-left: 10px;

          .nw-percent {
            font-weight: 500;
            color: #0cab41;
          }

          .nw-percent-neg {
            font-weight: 500;
            color: #f76552;
          }

          .upArrow {
            svg {
              path {
                fill: #0cab41;
              }
            }
          }

          .reverseArrow {
            margin-top: 3px;
          }
        }
      }
    }

    .nw-percent {
      color: #f76552;
    }

    .reverseArrow {
      transform: rotate(-180deg);
      margin-top: 5px;
      svg {
        path {
          fill: #f76552;
        }
      }
    }

    .upArrow {
      margin-top: -3px;
    }

    svg {
      height: 15px;
      width: 15px;
      cursor: pointer;
      margin-top: 4px;
    }

    .single-acc-margin {
      margin-top: 27px;
    }
  }

  .nw-note1 {
    margin-bottom: 5px;
    margin-top: 18px;
  }

  .nw-with-note {
    margin-top: 45px;
  }
}

.cw-note2,
.nw-note2 {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mrr-month {
  font-weight: 400;
  font-size: 14px;
  color: #2d2d2d;
  display: flex;
  align-items: center;
  justify-content: center;

  .mrr-month-bold {
    font-size: 14px;
    font-weight: 700;
  }

  .percentage {
    margin-top: 2px;
    margin-left: 10px;

    .nw-percent {
      font-weight: 500;
      color: #0cab41;
    }

    .nw-percent-neg {
      font-weight: 500;
      color: #f76552;
    }

    .upArrow {
      svg {
        path {
          fill: #0cab41;
        }
      }
    }

    .reverseArrow {
      margin-top: 3px;
    }
  }
}
