.widget-download-modal {
  .ant-modal-body {
    padding: 16px 24px !important;
  }
  .download-widget-wrap {
    .dw-title {
      font-weight: 700;
      font-size: 14px;
      padding-bottom: 6px;
    }

    .dw-search-list {
      .search-wrap {
        width: 100%;
        .search-account-input {
          width: 100%;
          border-radius: 6px 0 0 0;
          border-color: rgba(45, 45, 45, 0.2);
        }

        .search-btn {
          padding: 8px 10px 12px 0;
          border-radius: 0 6px 0 0;
          border-color: rgba(45, 45, 45, 0.2);
        }
      }
    }

    .dw-list {
      border: 1px solid rgba(45, 45, 45, 0.2);
      border-top: none;
      padding: 12px 15px;
      border-radius: 0 0 6px 6px;
      margin-bottom: 16px;
      height: 185px;
      overflow-y: auto;

      .widget-download-list-wrap {
        padding-bottom: 12px;
        display: flex;
        align-items: center;

        .checkbox-container {
          width: 26px;
          height: 16px;
          position: relative;

          input {
            position: absolute;
            top: 2px;
            left: 0;
            width: 14px;
            height: 14px;
            border: 1.4px solid #383f45;
          }

          input:checked {
            background-color: var(--primary-n);
            border-color: var(--primary-n);
          }

          .checkmark:after {
            content: '';
            position: absolute;
            display: none;
          }

          /* Show the checkmark when checked */
          input:checked ~ .checkmark:after {
            display: block;
          }

          .checkmark:after {
            left: 5px;
            top: 4px;
            width: 4px;
            height: 9px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            cursor: pointer;
          }
        }
      }
    }

    .dw-buttons-wrap {
      display: flex;
    }
  }

  //scrollbar styles
  ::-webkit-scrollbar {
    width: 8px;
    margin-top: 15px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #88898a7a;
    background: #f7655228;
    margin: 8px 0 18px 0;
    border-radius: 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--primary-n);
    border-radius: 0;
  }
}
