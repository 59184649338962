input[type='text'],
input[type='email'],
input[type='number'],
input[type='password'] {
  min-height: 44px;
}

input[type='password'] {
  font-family: caption;
  font-size: 24px;

  &::placeholder {
    font-family: $font-family-base;
    font-size: 16px;
  }
}

input[type='checkbox'] {
  appearance: none;
  border: 1.5px solid $secondary;
  box-sizing: border-box;
  border-radius: 2px;
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  cursor: pointer;
  position: relative;
  margin-right: 12px;

  &:checked:after {
    content: '';
    width: 6px;
    height: 6px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: $secondary;
  }
}

.form-check {
  position: relative;
  display: flex;
}

// .form-group {
//   margin-bottom: 30px;
// }

.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
}
